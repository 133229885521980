import React from 'react';
import DateTimeSchedule from '../../form/components/DateTimeSchedule';

const EventsSchedule = ({railsModel, railsField, railsLabel, initSchedule}) => {
    

    return (
        <div>
            <label className="form-label">{ railsLabel }</label>

            <DateTimeSchedule
                railsModel={ railsModel } 
                railsField={ railsField }
                railsLabel={ railsLabel }
                initSchedule={ initSchedule }
            />
        </div>
    );
}

export default EventsSchedule;
