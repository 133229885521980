import React, { useState } from "react";
import PropTypes from "prop-types";
import ArticleTr from "./ArticleTr";

const ArticleTable = ({
  articles,
  pendingArticles,
  pending,
  onToggle,
  searchBar = true,
  pendingTable = false,
}) => {
  const [searchResults, setSearchResults] = useState(articles);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearchResults(articles);
      return;
    }

    const term = new RegExp(`${e.target.value}.*$`, "i");
    const result = searchResults.filter((e) => e.title.search(term) !== -1);

    setSearchResults(result);
  };

  const handlePending = () => {
    onToggle(!pending);

    if (pending) {
      setSearchResults(articles);
    } else {
      setSearchResults(pendingArticles);
    }
  };

  return (
    <>
      <div className="header-search item-list__top__search w-full">
        {searchBar && (
          <input
            onChange={(e) => handleSearch(e)}
            placeholder="Buscar artículo"
          />
        )}
        {pendingTable && (
          <button className="btn btn-publish" onClick={handlePending}>
            {pending ? "Ver publicados" : "Ver pendientes"}
          </button>
        )}
      </div>
      <div className="p-3">
        <table className="table-general">
          <thead>
            <tr>
              <th>Título</th>
              <th>Fecha</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.slice(0, 10).map((article) => (
              <ArticleTr key={article.title} article={article} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

ArticleTable.propTypes = {
  articles: PropTypes.array,
};

export default ArticleTable;
