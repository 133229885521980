import React, { useState, useEffect } from 'react'

const useArticleFilters = () => {
    const [urlSearch, setUrlSearch] = useState({
        category: '',
        status: '',
        author: '',
        type: '',
    });

    const [searchInput, setSearchInput] = useState('');
    const [inputSearchSubmited, setInputSearchSubmited] = useState(false);

    const [originalUrlParams, setOriginalUrlParams] = useState({
        category: '',
        status: '',
        author: '',
        type: '',
    });

    useEffect(() => {
        // Get url path on load page
        const newUrlSearch = window.location.search
        if (newUrlSearch) {
            const temporalUrlSearch = newUrlSearch.slice(1).split('&');
            temporalUrlSearch.forEach(item => {
                const itemArray = item.split('=')
                
                if (urlSearch.hasOwnProperty(itemArray[0])) {
                    setUrlSearch(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setOriginalUrlParams(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setSearchInput(urlSearch.search);
                }
            });
        }
        
    }, []);


    useEffect(() => {
        if (originalUrlParams) {
            if (
                originalUrlParams.category != urlSearch.category ||
                originalUrlParams.status != urlSearch.status ||
                originalUrlParams.author != urlSearch.author ||
                originalUrlParams.type != urlSearch.type
            ) {
                const newUrlParams = `${window.location.origin}${window.location.pathname}?${generateUrlParams()}`;
                window.location.href = newUrlParams;
            }
        }
    }, [urlSearch]);

    useEffect(() => {
        if (inputSearchSubmited == true) {
            setUrlSearch({
                ...urlSearch,
                search: searchInput
            });
        }
    }, [inputSearchSubmited])

    const generateUrlParams = () => {
        const newUrlParams = {}
        if (urlSearch.category) {
            newUrlParams['category'] = urlSearch.category;
        }
        if (urlSearch.status) {
            newUrlParams['status'] = urlSearch.status;
        }
        if (urlSearch.author) {
            newUrlParams['author'] = urlSearch.author;
        }
        if (urlSearch.type) {
            newUrlParams['type'] = urlSearch.type;
        }

        const urlParameters = Object.entries(newUrlParams).map(e => e.join('=')).join('&');
        return urlParameters
    }

    const selectOption = (target) => {
        if (urlSearch.hasOwnProperty(target.name)) {
            setUrlSearch(urlSearch => ({
                ...urlSearch,
                [target.name]: target.value
            }));
        }
    }

    return {
        urlSearch,
        searchInput,
        selectOption,
        setSearchInput,
    };
}

export { useArticleFilters }
