import React from 'react';
import PropTypes from 'prop-types';
import PreviewImage from '../../components/PreviewImage';
import DropZone from '../../slideshow/components/DropZone';

const NoBanner = () => (
  <div className="banner-not-allowed">
    <p>
      No disponible en tu plan actual
    </p>
  </div>
);

const Banners = ({ count, inputFiles, slideshowAllowed, changePlan }) => {
  const { 
    bannerSlides, bannerLg, bannerMd, bannerSm, 
    mobileBannerSlides, mobileBannerLg, mobileBannerMd 
  } = inputFiles;

  return (
    <div className="row banner-wrapper">
      <div className="col-12 col-lg-7 banner-wrapper__desktop">
        {slideshowAllowed && (
          <div className="banner-drop">
            <h3>Banner slideshow - Desktop</h3>
            {count == 4 ? <DropZone images={bannerSlides} resource="commerce[banner_slides]" /> : <NoBanner />}
            <p className="mt-2 mb-3">
              Tamaño recomendado 1830x500px
            </p>
          </div>
        )}
        
        {count == 4 && (
          <div className="banner-drop">
            <h3>Mega Banner - Desktop</h3>
            <PreviewImage railsInputs={{ inputFile: bannerLg }} fileName="bannerLg" plan="Plan Pro Platino" />
            <p className="mt-2 mb-3">
              Tamaño recomendado 1830x500px
            </p>
            {changePlan && (
              <a href={changePlan} class="btn-publish">Cambiar plan</a>
            )}
          </div>
        )}

        {count == 3 && (
          <div className="banner-drop">
            <h3>Banner Grande - Desktop</h3>
            <PreviewImage railsInputs={{ inputFile: bannerMd }} fileName="bannerMd" plan="Plan Pro Oro" />
            <p className="mt-2 mb-3">
              Tamaño recomendado 900x500px
            </p>
            {changePlan && (
              <a href={changePlan} class="btn-publish">Cambiar plan</a>
            )}
          </div>
        )}

        {count == 2 && (
          <div className="banner-drop col-8 --squared">
            <h3>Banner pequeño</h3>
            <PreviewImage railsInputs={{ inputFile: bannerSm }} fileName="bannerSm" plan="Plan Pro Plata" />
            <p className="mt-2 mb-3">
              Tamaño recomendado 430x300px
            </p>
            {changePlan && (
              <a href={changePlan} class="btn-publish">Cambiar plan</a>
            )}
          </div>
        )}
      </div>

      <div className="col-12 col-lg-4 banner-wrapper__mobile">
        {slideshowAllowed && (
          <div className="banner-drop --mobile">
            <h3>Banner slideshow - Mobile</h3>
            {count == 4 ? <DropZone images={mobileBannerSlides} resource="commerce[mobile_banner_slides]" /> : <NoBanner />}
            <p className="mt-2 mb-3">
              Tamaño recomendado 654x368px
            </p>
          </div>
        )}

        {count == 4 && (
          <div className="banner-drop">
            <h3>Mega Banner - Mobile</h3>
            <PreviewImage railsInputs={{ inputFile: mobileBannerLg }} fileName="mobileBannerLg" plan="Plan Pro Platino" />
            <p className="mt-2 mb-3">
              Tamaño recomendado 654x368px
            </p>
            {changePlan && (
              <a href={changePlan} class="btn-publish">Cambiar plan</a>
            )}
          </div>
        )}

        {count == 3 && (
          <div className="banner-drop">
            <h3>Banner Grande - Mobile</h3>
            <PreviewImage railsInputs={{ inputFile: mobileBannerMd }} fileName="mobileBannerMd" plan="Plan Pro Oro" />
            <p className="mt-2 mb-3">
              Tamaño recomendado 312x312px
            </p>
            {changePlan && (
              <a href={changePlan} class="btn-publish">Cambiar plan</a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Banners.propTypes = {
  count: PropTypes.number.isRequired,
  inputFiles: PropTypes.object.isRequired,
};

export default Banners;
