import React, { useState } from 'react';
import PropTypes from 'prop-types';
import autocompleteSearchUrl from './autocompleteUrl';

const SearchBarUrl = ({ searchData, name }) => {
  const [addSuggest, setAddSuggest] = useState(true);

  const handleSuggestions = (e) => {
    if (addSuggest) {
      autocompleteSearchUrl(searchData, e.target, name);
      setAddSuggest(false);
    }
  };

  return (
    <input
      type="text"
      id="autoComplete"
      placeholder="Buscar"
      onClick={(e) => handleSuggestions(e)}
      autoComplete="off"
      name={name}
    />
  );
};

SearchBarUrl.propTypes = {
  searchData: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
};

export default SearchBarUrl;
