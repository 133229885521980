import React from "react";
import DisplayPreview from "./DisplayPreview";

const Display = ({
  articles,
  urls,
  authenticityToken,
  cardOptions,
  quantity
}) => (
  <DisplayPreview
    urls={urls}
    articles={articles}
    authenticityToken={authenticityToken}
    cardOptions={cardOptions}
    size={quantity}
  />
);

export default Display;
