import React from 'react'
import PopUp from '../../layout/PopUp'
import { useContext } from 'react'
import { CreatePlaylistContext } from '../../contexts/CreatePlaylistContext'
import { useState } from 'react'
import VideoMeilisearch from './VideoMeilisearch'

const closeIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z" fill="#021D49"/>
  </svg>
);

const PopupAddVideo = React.memo(({ closePopup }) => {
  const { setPlaylistVideos, urls, urlParams, playlistVideos } = useContext(CreatePlaylistContext);
  const [selectedVideos, setSelectedVideos] = useState(playlistVideos);
  const [videos, setVideos] = useState([]);
  const handleClosePopUp = () => closePopup();

  const savePlaylist = () => {
    setPlaylistVideos(selectedVideos);
    closePopup();
  }

  const handleClickVideo = (status, video) => {
    if( status ) {
      addVideo(video);
    } else {
      removeVideo(video);
    }
  }

  const handleSelectAll = ({ target }) => {
    console.log(target.checked)
    
    if (target.checked) {
      setSelectedVideos(videos);
    } else {
      setSelectedVideos([]);
    }
  }

  const getVideoIds = () => selectedVideos.map(video => video.id)
  const checkVideoId = (videoId) => getVideoIds().includes(videoId);

  const addVideo = (video) => setSelectedVideos(item => ([...item, video]));
  const removeVideo = (video) => setSelectedVideos(item => (
    item.filter(videoItem => videoItem.id !== video.id)
  ));

  return (
    <PopUp closeAction={ closePopup }>
      <div className="popup__header border-0">
          <div className='d-flex align-items-center'>
            <h3>Añadir videos</h3>
            <p className='m-0 text-muted mx-2'>Nombre de Playlist</p>
          </div>
          <div className="popup__header__close">
              <button
                className="popup__header__close__button --red-button"
                onClick={ handleClosePopUp }
              >
               {closeIcon}
              </button>
          </div>
      </div>
      <div className="popup__body">

        <div className="playlist__popup">
          <VideoMeilisearch setVideos={ setVideos } />
          <label className='my-2'>
            <input
              type='checkbox'
              name=''
              className='mx-2'
              onClick={(e) => handleSelectAll(e)}
            />
            <span className='text-muted'>Seleccionar todos</span>
          </label>
          <div className="playlist__popup__videos " >
          {
            videos.map(video => (
              <label className='video' key={ `${video.title}-${video.id}` }>
                <input
                  type='checkbox'
                  name=''
                  onChange={({target}) => handleClickVideo(target.checked, video) }
                  checked={checkVideoId(video.id)}
                />
                <div className="video__image">
                  <img src={ video.get_thumbnail } alt={ video.title } />
                </div>
                <p className="video__title">{ video.title }</p>
                <p className="video__duration">{ video.duration }</p>
                <p className="video__date">{ video.date }</p>
              </label>
            ))
          }
          </div>
        </div>
      </div>
      <div className="popup__footer justify-content-center">
          <button
            className="btn btn-return"
            onClick={ handleClosePopUp }
          >Cancelar</button>
          <button onClick={ savePlaylist } className="btn btn-publish mx-3 w-auto h-auto text-danger m-0">Guardar</button>
      </div>
    </PopUp>
  )
});

PopupAddVideo.propTypes = {}

export default PopupAddVideo
