import React from "react";

const ArticleTr = ({ article }) => {
  const {
    title,
    description,
    date,
    urls: { edit },
  } = article;

  const handleDragArticle = (e) => {
    e.dataTransfer.setData("article", JSON.stringify(article));
  };

  return (
    <tr>
      <td
        className="td-info"
        title={description}
        draggable
        onDragStart={handleDragArticle}
      >
        <p className="td-title">{title}</p>
      </td>
      <td>{date}</td>
      <td>
        <div className="options">
          <a href={edit} className="modify-icon">
            <i className="fas fa-pen"></i>
          </a>
        </div>
      </td>
    </tr>
  );
};

export default ArticleTr;
