import { useState } from "react";

const useEnable = (initialState = false) => {
    const [isEnable, setIsEnable] = useState(false);

    const changeIsEnable = (value) => {
        if (typeof value !== "boolean") {
            console.log('This hook only accepts boolean value')
            return;
        }
        setIsEnable(value);
    }

    return [isEnable, changeIsEnable]
}

export { useEnable }