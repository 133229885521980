import React from 'react'

const PlaylistSendForm = ({isUpdate, values, urls, authenticityToken, formRef}) => {

  return (
    <form method='post' encType="multipart/form-data" ref={ formRef } autoComplete="off" action={ urls }>
      {
        isUpdate &&
          <input type="hidden" name="_method" value="PUT" />
      }

      <input type='hidden' name='playlist[name]' value={ values.title } />

      {
        values.tags.map(tag => (
          <input key={tag.value} type='hidden' name='playlist[ott_tag_ids][]' value={ tag.value } />
        ))
      }

      <input type="hidden" name="playlist[order_type]" value={values.order} />

      <input
        type='hidden'
        name='playlist[ott_videos_order]'
        value={JSON.stringify(values.videos)}
      />

      <input type="hidden" name="authenticity_token" value={ authenticityToken } />
    </form>
  )
}

PlaylistSendForm.propTypes = {}

export default PlaylistSendForm
