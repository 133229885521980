import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEnable } from '../../hooks/useEnable';

const EventsTickets = ({ inputValue, inputName, inputModel }) => {
    const [isEnable, setIsEnable] = useEnable(false);

    const handleChangeEnable = ({ target }) => {
        if (target.checked) {
            setIsEnable(false);
        } else {
            setIsEnable(true);
        }
    }

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setIsEnable(true);
        }
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap">
                <label className="form-label" htmlFor="set_publication">Link boletos online</label>
                <label className="switch --switch-dark ml-2">
                    <input 
                        type="checkbox" 
                        id="set_publication" 
                        onChange={ handleChangeEnable }
                        name={`${inputModel}[not_${inputName}]`}
                        checked={!isEnable}
                    />
                    <span className="slider"></span>
                </label>
            </div>
            <input
                type="text"
                className="mb-3 form-control"
                name = {`${inputModel}[${inputName}]`}
                placeholder='Ingresa el link de la boleteria'
                disabled = {isEnable}
                defaultValue={inputValue}
            />
        </div>
    );
}

EventsTickets.propTypes = {
    inputValue: PropTypes.string,

}

export default EventsTickets;
