import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DateTimeSchedule from '../../form/components/DateTimeSchedule'

const EventsSalesSchedule = ({railsModel, railsField, railsLabel, initSchedule, initIsEnable}) => {
    const [isEnable, setIsEnable] = useState(typeof initIsEnable === 'boolean' ? initIsEnable : false);

    const handleChangeEnable = () => {
        setIsEnable(!isEnable);
    }


    return (
        <div>
            
            <div className="d-flex justify-content-between flex-wrap">
                <label className="form-label" htmlFor="set_publication">Preventa</label>
                <label className="switch --switch-dark ml-2">
                    <input 
                        type="checkbox" 
                        id="set_publication" 
                        onChange={ handleChangeEnable }
                        name="ea"
                        checked={isEnable}
                    />
                    
                    <span className="slider"></span>
                </label><input
                        type="hidden"
                        name={`${railsModel}[presale]`}
                        value={ isEnable }
                    />
            </div>

            <DateTimeSchedule
                railsModel={ railsModel } 
                railsField={ railsField }
                railsLabel={ railsLabel }
                initSchedule={ initSchedule }
                isEnable={ !isEnable }
            />
        </div>
    )
}

EventsSalesSchedule.propTypes = {

}

export default EventsSalesSchedule
