import React from "react";
import { useState } from "react";
import ArticlePopup from "./ArticlePopup";

const pencilIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1067 6.07161L9.92833 4.89328L2.16667 12.6549V13.8333H3.345L11.1067 6.07161ZM12.285 4.89328L13.4633 3.71495L12.285 2.53661L11.1067 3.71495L12.285 4.89328ZM4.035 15.4999H0.5V11.9641L11.6958 0.768281C11.8521 0.612055 12.064 0.524292 12.285 0.524292C12.506 0.524292 12.7179 0.612055 12.8742 0.768281L15.2317 3.12578C15.3879 3.28205 15.4757 3.49398 15.4757 3.71495C15.4757 3.93592 15.3879 4.14784 15.2317 4.30411L4.03583 15.4999H4.035Z"
      fill="#FCFCFC"
    />
  </svg>
);

const deleteIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0006 4.82166L10.1256 0.696655L11.3039 1.87499L7.17893 5.99999L11.3039 10.125L10.1256 11.3033L6.0006 7.17832L1.8756 11.3033L0.697266 10.125L4.82227 5.99999L0.697266 1.87499L1.8756 0.696655L6.0006 4.82166Z"
      fill="white"
    />
  </svg>
);

const ArticleCard = ({
  title,
  description,
  category,
  date,
  articles,
  addArticleCard,
  cardOptions,
  articleArea,
  removeArticleCard,
  id,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const handleRemoveCardArticle = (order) => {
    removeArticleCard(order);
  };

  const handleDragArticle = (e) => {
    e.dataTransfer.setData(
      "article",
      JSON.stringify({
        title,
        description,
        date,
        category,
        id,
      })
    );
  };

  return (
    <div className="todo-card" draggable onDragStart={handleDragArticle}>
      <div className="todo-card__content">
        <div className="todo-card__content__buttons">
          <div className="todo-card__content__buttons__button --category">
            {category.toUpperCase()}
          </div>
        </div>
        <h3 className="todo-card__content__title">{title}</h3>
      </div>
      <div className="todo-card__hover">
        <div className="todo-card__hover__options">
          <button
            className="d-md-none todo-card__hover__options__option --edit"
            onClick={() => setShowPopUp(true)}
          >
            {pencilIcon}
          </button>
          <button
            className="d-none d-md-block todo-card__hover__options__option --delete"
            onClick={() => handleRemoveCardArticle(articleArea)}
          >
            {deleteIcon}
          </button>
        </div>
      </div>
      {showPopUp && (
        <ArticlePopup
          addArticleCard={addArticleCard}
          cardOptions={cardOptions}
          setShowPopUp={setShowPopUp}
          articleArea={articleArea}
          articles={articles}
        />
      )}
    </div>
  );
};

export default ArticleCard;
