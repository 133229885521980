import React, { useState } from 'react';
import Conekta from './Conekta';

const ShowConektaModal = ({ publicKey, token, customer_id, url, authenticity_token:authenticityToken }) => {
    const [showConektaModal, setShowConektaModal] = useState(false);
    const [hiddenModal, setHiddenModal] = useState(true);

    const handleClosePopUp = () => {
        setHiddenModal(true);
    }

    const handleOpenPopUp = () => {
        if ( !showConektaModal ) {
            setShowConektaModal(true);
        }
        setHiddenModal(false);
    }

    return (
        <>
            
                    <div className={`popup-container ${ hiddenModal ? 'hidden-element' : ''}`}>
                        <div className="popup popup-conekta">
                            <div className="popup__header">
                                <div></div>
                                <div className="popup__header__close">
                                    <button
                                        className="popup__header__close__button"
                                        onClick={ handleClosePopUp }
                                    >
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z" fill="#021D49"/>
                                        </svg>
                                    </button>
                                </div> 
                            </div>
                            <div className="popup__body" id="conektaIframeContainer">
                                {
                                    showConektaModal &&
                                        <Conekta
                                            publicKey={ publicKey }
                                            token={ token }
                                            customer_id={ customer_id }
                                            url={ url }
                                            authenticityToken={ authenticityToken }
                                        />
                                }
                            </div>
                        </div>
                    </div>
            
            <span className="billing-page__cards__header__edit" onClick={ handleOpenPopUp }>Modificar</span>
        </>
    );
}

ShowConektaModal.propTypes = {

}

export default ShowConektaModal;