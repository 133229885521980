import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DaySchedule = ({
  day, name, title, updateSchedules,
}) => {
  const [schedule, setSchedule] = useState(day.length > 0 ? day : [{ opening: '', closing: '' }]);

  const handleTimeChange = (index, time, value) => {
    const newSchedule = schedule;
    newSchedule[index][time] = value;

    setSchedule(newSchedule);
    updateSchedules(name, newSchedule);
  };

  const addDaySchedule = () => {
    const newSchedule = schedule;
    newSchedule.push({ opening: '', closing: '' });

    setSchedule(newSchedule);
    updateSchedules(name, newSchedule);
  };

  const removeDaySchedule = (index) => {
    const newSchedule = [...schedule];
    newSchedule.splice(index, 1);

    if (index === 0) {
      newSchedule.push({ opening: '', closing: '' });
    }

    setSchedule(newSchedule);
    updateSchedules(name, newSchedule);
  };

  const handleInputType = (e) => {
    e.target.type = 'time';
  };

  return (
    <>
      {schedule.map((times, index) => (
        <div className="day-schedule" key={`${name}${index}`}>
          {index === 0 && <h5>{title}</h5>}

          <input
            type={times.opening === '' ? 'text' : 'time'}
            value={times.opening}
            step="900"
            placeholder="Apertura"
            onChange={(e) => handleTimeChange(index, 'opening', e.target.value)}
            onFocus={(e) => handleInputType(e)}
          />
          <input
            type={times.closing === '' ? 'text' : 'time'}
            value={times.closing}
            step="900"
            placeholder="Cierre"
            onChange={(e) => handleTimeChange(index, 'closing', e.target.value)}
            onFocus={(e) => handleInputType(e)}
          />

          <div className="day-schedule__buttons">
            {index === (schedule.length - 1)
                && (
                <button type="button" className="rounded --add" onClick={addDaySchedule}>
                  <i className="fas fa-plus" />
                </button>
                )}

            {(index > 0 || [times.opening, times.closing].some((t) => t !== ''))
                && (
                <button type="button" className="rounded --remove" onClick={() => removeDaySchedule(index)}>
                  <i className="fas fa-times" />
                </button>
                )}
          </div>
        </div>
      ))}
    </>
  );
};

DaySchedule.propTypes = {
  day: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  updateSchedules: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default DaySchedule;
