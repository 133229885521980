import React from 'react';
import PopUpDelete from '../../components/PopUpDelete';
import PopUpEditEventSlideshow from '../../components/PopUpEditEventSlideshow';


const deleteButton = `<a class="btn btn-danger"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.0006 4.82166L10.1256 0.696655L11.3039 1.87499L7.17893 5.99999L11.3039 10.125L10.1256 11.3033L6.0006 7.17832L1.8756 11.3033L0.697266 10.125L4.82227 5.99999L0.697266 1.87499L1.8756 0.696655L6.0006 4.82166Z" fill="white"/>
</svg></a>`



const EventSlideshowCard = ({ event, authenticityToken }) => {
  return (
    <div className="slideshow-card">
      <img className="slideshow-card__image" src={ event.railsInputs.image } />
      <div className="slideshow-card__options">
        <PopUpDelete button={ deleteButton } text="¿Estas seguro de querer eliminar la portada?" url={ event.url } />
        <PopUpEditEventSlideshow event={ event } url={ event.url } authenticityToken={ authenticityToken } />
      </div>
    </div>
  );
}

export default EventSlideshowCard;
