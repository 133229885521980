import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const PreviewImageImprove = ({ initialImage, aditionalClassName, accept, inputData, onChangeImage }) => {
    // Icons
    const TrashIcon = (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0265 3.33339H13.2316V4.61544H11.9496V12.9488C11.9496 13.1188 11.8821 13.2818 11.7618 13.4021C11.6416 13.5223 11.4786 13.5898 11.3086 13.5898H2.33421C2.1642 13.5898 2.00115 13.5223 1.88094 13.4021C1.76072 13.2818 1.69318 13.1188 1.69318 12.9488V4.61544H0.411133V3.33339H3.61626V1.41031C3.61626 1.2403 3.6838 1.07725 3.80401 0.957039C3.92423 0.836824 4.08728 0.769287 4.25729 0.769287H9.38549C9.5555 0.769287 9.71855 0.836824 9.83877 0.957039C9.95898 1.07725 10.0265 1.2403 10.0265 1.41031V3.33339ZM10.6675 4.61544H2.97524V12.3077H10.6675V4.61544ZM4.89831 6.53852H6.18036V10.3847H4.89831V6.53852ZM7.46242 6.53852H8.74447V10.3847H7.46242V6.53852ZM4.89831 2.05134V3.33339H8.74447V2.05134H4.89831Z" fill="#6E7191"/>
        </svg>
    );
    const PictureIcon = (
        <svg width="67" height="74" viewBox="0 0 67 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 59.5L28 42L34.58 48.58C32.655 51.765 31.5 55.51 31.5 59.5H10.5ZM21 29.75C21 26.845 18.655 24.5 15.75 24.5C12.845 24.5 10.5 26.845 10.5 29.75C10.5 32.655 12.845 35 15.75 35C18.655 35 21 32.655 21 29.75ZM31.815 63H7V7H31.5V24.5H49V38.815C50.155 38.64 51.31 38.5 52.5 38.5C53.69 38.5 54.845 38.64 56 38.815V21L35 0H7C3.115 0 0 3.15 0 7V63C0 66.885 3.115 70 7 70H34.335C33.11 67.865 32.235 65.52 31.815 63ZM49 45.5V56H38.5V63H49V73.5H56V63H66.5V56H56V45.5H49Z" fill="#E31519" fillOpacity="0.2"/>
        </svg>
    );

    const [fileUrl, setFileUrl] = useState(initialImage);
    const inputImage = useRef();

    //article_inputFile

    const handleAddImage = ({ target }) => {
        if (onChangeImage) {
            onChangeImage(target);
        }

        const imageFile = target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        const beforeState = fileUrl;
        setFileUrl(imageUrl);

        if (validateImage(target.value)) {
            inputImage.current.files = target.files;
        } else {
            setFileUrl(beforeState);
        }
    }

    const handleRemoveImage = (e) => {
        e.preventDefault();
        setFileUrl(null);
    }

    const validateImage = (image) => {
        const pattern = (/\.(jpg|jpeg|png|gif|webp|svg)$/i);

        if (!pattern.test(image)) {
            alert('Este tipo de archivo no está permitido');
            return false;
        }

        return true;
    }

    return (
        <div className={`${aditionalClassName} ${ fileUrl ? '--active' : '' } preview-file`}>
            {
                fileUrl ?
                    <>
                        <button
                            className="preview-file__delete-button"
                            onClick={ handleRemoveImage }
                        >{ TrashIcon }</button>
                        <img src={fileUrl} className="preview-file__file" />
                    </>
                    :
                    <div className="preview-file__indications">
                        <div className="indications__icon">{ PictureIcon }</div>
                    </div>
            }
            <input
                type='file'
                className="preview-file__input-file"
                accept={accept}
                onChange={ handleAddImage }
                onDragOver={(e) => {e.preventDefault()}}
                name={`${inputData.model}[${inputData.name}]` }
                onDrop={ handleAddImage }
                ref={ inputImage }
            />
        </div>
    );
}

PreviewImageImprove.propTypes = {

}

export default PreviewImageImprove;