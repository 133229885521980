import React from "react";
import { useState } from "react";

const clipboard = (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 17V6C1 4.89543 1.89543 4 3 4H10C11.1046 4 12 4.89543 12 6V17C12 18.1046 11.1046 19 10 19H3C1.89543 19 1 18.1046 1 17Z" stroke="black" stroke-width="1.5"/>
    <path d="M4 1L10.5185 1H12C13.6569 1 15 2.34315 15 4V11" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
);

const done = (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 17V6C1 4.89543 1.89543 4 3 4H10C11.1046 4 12 4.89543 12 6V17C12 18.1046 11.1046 19 10 19H3C1.89543 19 1 18.1046 1 17Z" stroke="black" stroke-width="1.5"/>
    <path d="M4 1L10.5185 1H12C13.6569 1 15 2.34315 15 4V11" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M4 12.2857L4.85243 14.8186C4.99624 15.246 5.58767 15.2796 5.77904 14.8714L9 8" stroke="black" stroke-linecap="square"/>
  </svg>
)

const CopyToClipboard = ({ copy }) => {
  const [copied, setCopied] = useState(false);

  const hanldeClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(copy);
  }
  
  return (
    <button
      onClick={hanldeClick}
      type="button"
      title={copied ? "Enlace copiado" : "Copiar enlace"}
      style={{ border: "none", backgroundColor: "transparent" }}
    >
      {copied ? done : clipboard}
    </button>
  );
};

export default CopyToClipboard;
