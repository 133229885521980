import React from "react";
import DisplayPreview from "./DisplayPreview";
import ArticleTable from "./ArticleTable";
import { useState } from "react";

const TopFour = ({
  articles,
  pendingArticles,
  urls,
  authenticityToken,
  publishedCards,
  pendingCards,
}) => {
  const [pending, setPending] = useState(false);

  return (
    <div class="todo-home">
      <div class="todo-home__display">
        <DisplayPreview
          articles={articles}
          urls={urls}
          authenticityToken={authenticityToken}
          cardOptions={pending ? pendingCards : publishedCards}
          size={4}
          pending={pending}
        />
      </div>
      <div className="todo-home__table d-none d-md-block">
        <ArticleTable pending={pending} pendingTable={true} onToggle={setPending} articles={articles} pendingArticles={pendingArticles} />
      </div>
    </div>
  );
};

export default TopFour;
