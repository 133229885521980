import React, { useState, useEffect } from 'react';

const PreviewImage = ({ railsInputs, aditionalClassName, fileName, plan }) => {
    // Icons
    const TrashIcon = (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0265 3.33339H13.2316V4.61544H11.9496V12.9488C11.9496 13.1188 11.8821 13.2818 11.7618 13.4021C11.6416 13.5223 11.4786 13.5898 11.3086 13.5898H2.33421C2.1642 13.5898 2.00115 13.5223 1.88094 13.4021C1.76072 13.2818 1.69318 13.1188 1.69318 12.9488V4.61544H0.411133V3.33339H3.61626V1.41031C3.61626 1.2403 3.6838 1.07725 3.80401 0.957039C3.92423 0.836824 4.08728 0.769287 4.25729 0.769287H9.38549C9.5555 0.769287 9.71855 0.836824 9.83877 0.957039C9.95898 1.07725 10.0265 1.2403 10.0265 1.41031V3.33339ZM10.6675 4.61544H2.97524V12.3077H10.6675V4.61544ZM4.89831 6.53852H6.18036V10.3847H4.89831V6.53852ZM7.46242 6.53852H8.74447V10.3847H7.46242V6.53852ZM4.89831 2.05134V3.33339H8.74447V2.05134H4.89831Z" fill="#6E7191"/>
        </svg>
    );
    const PictureImage = (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2497 11.75V14.5H20.9997V16.3333H18.2497V19.0833H16.4163V16.3333H13.6663V14.5H16.4163V11.75H18.2497ZM18.257 0.75C18.7593 0.75 19.1663 1.15792 19.1663 1.66025V9.91667H17.333V2.58333H2.66634V15.4157L11.833 6.25L14.583 9V11.5933L11.833 8.84325L5.25776 15.4167H11.833V17.25H1.74234C1.50109 17.2498 1.2698 17.1537 1.09929 16.9831C0.928783 16.8124 0.833008 16.581 0.833008 16.3397V1.66025C0.834685 1.41951 0.931 1.1891 1.10114 1.01878C1.27129 0.848466 1.50161 0.75192 1.74234 0.75H18.257ZM6.33301 4.41667C6.81924 4.41667 7.28555 4.60982 7.62937 4.95364C7.97319 5.29745 8.16634 5.76377 8.16634 6.25C8.16634 6.73623 7.97319 7.20255 7.62937 7.54636C7.28555 7.89018 6.81924 8.08333 6.33301 8.08333C5.84678 8.08333 5.38046 7.89018 5.03664 7.54636C4.69283 7.20255 4.49967 6.73623 4.49967 6.25C4.49967 5.76377 4.69283 5.29745 5.03664 4.95364C5.38046 4.60982 5.84678 4.41667 6.33301 4.41667Z" fill="#6E7191"/>
        </svg>
    );
    
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        const imageParams = document.querySelector(`#preview_file_${fileName} input[type="file"]`);
        if(imageParams) {
            setFileUrl(imageParams.src);
        }else {
            console.log('Image param is empty');
        }
    }, []);

    //article_inputFile

    const handleAddImage = ({ target }) => {
        const imageFile = target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        const beforeState = fileUrl;
        setFileUrl(imageUrl);

        if (validateImage(target.value)) {
            document.querySelector(`#preview_file_${fileName} input[type="file"]`).files = target.files;
        } else {
            setFileUrl(beforeState);
            console.log('Error on upload image');
        }
    }

    const handleRemoveImage = (e) => {
        e.preventDefault();
        setFileUrl(null);
    }
    
    const validateImage = (image) => {
        const pattern = (/\.(jpg|jpeg|png|gif|webp|svg)$/i);

        if (!pattern.test(image)) {
            alert('El archivo a adjuntar no es una imagen');
            return false;
        }

        return true;
    }

    return (
        <div className={`${aditionalClassName} preview-file`}>
            {
                fileUrl ?
                    <>
                        <button
                            className="preview-file__delete-button"
                            onClick={ handleRemoveImage }
                        >{ TrashIcon }</button>
                        <img src={fileUrl} className="preview-file__file" />
                    </>
                    :
                    <div className="preview-file__indications">
                        {plan && (
                            <>
                                <h3 className="indications__plan">
                                    {plan}
                                    <br />
                                    Plan actual
                                </h3>
                            </>
                        )}
                        <div className="indications__icon">{ PictureImage }</div>
                        <p className="indications__text">Arrastre o elija la imagen</p>
                    </div>
            }
            <input
                type='file'
                className="preview-file__input-file"
                onChange={ handleAddImage }
                onDragOver={(e) => {e.preventDefault()}}
                onDrop={ handleAddImage }
            />
            <div
                className="display-none"
                id={`preview_file_${fileName}`}
                dangerouslySetInnerHTML={{__html: railsInputs.inputFile}}
            ></div>
        </div>
    );
}

PreviewImage.propTypes = {

}

export default PreviewImage;