import React from 'react';
import EventsTrPopUp from './EventsTrPopUp';

const EventsPopUp = ({ closePopUp, icons, addElement, removeElement, eventIds, authenticityToken, urls, events }) => {
    const closeIcon = (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 25.5C6.09625 25.5 0.5 19.9038 0.5 13C0.5 6.09625 6.09625 0.5 13 0.5C19.9037 0.5 25.5 6.09625 25.5 13C25.5 19.9038 19.9037 25.5 13 25.5ZM13 11.2325L9.465 7.69625L7.69625 9.465L11.2325 13L7.69625 16.535L9.465 18.3038L13 14.7675L16.535 18.3038L18.3037 16.535L14.7675 13L18.3037 9.465L16.535 7.69625L13 11.2325Z" fill="#A0A3BD"/>
        </svg>
    );
    

    return (
        <div className="popup-container">
            <div className="popup popup-large">
                <div className="popup__header">
                    <div className="popup__header__description">
                        <h2 className="popup__header__description__title">
                            Lista general de eventos
                        </h2>
                        <p className="popup__header__description__subtitle">
                            Elige los eventos que deseas mostrar en la pagina principal
                        </p>
                    </div>
                    <div className="popup__header__close">
                        <form method="post" action={ urls.form } >
                            <input type="hidden" name="_method" value="PUT" />
                            <input
                                type="hidden"
                                name="authenticity_token"
                                value={ authenticityToken }                
                            />
                            <input
                                type="hidden"
                                name="content"
                                value={ JSON.stringify(eventIds) }
                            />
                            <button
                                className="popup__header__close__button"
                            >
                                { closeIcon }
                            </button>
                        </form>
                    </div>
                </div>
                <hr />
                <div className="popup__body">
                    <div className="popup__body__search popup__body__search__large">
                        <div className="input-search large">
                            <div className="icon">
                                <i className="fas fa-search"></i>
                            </div>
                            <input type="text" placeholder="Buscar" />
                        </div>
                        <div className="filter">
                            <label>Ordenar por</label>
                            <select className="form-select p-1" aria-label="Default select example">
                                <option defaultValue>Destacados</option>
                            </select>
                        </div>
                    </div>
                    <table className="tp-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Título</th>
                                <th>Categoría</th>
                                <th>Precio</th>
                                <th>Fecha</th>
                                <th
                                    dangerouslySetInnerHTML={{ __html: icons.house }}
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                events.map(event => (
                                    <EventsTrPopUp
                                        key={ event.id }
                                        event={ event } 
                                        addElement={ addElement }
                                        removeElement={ removeElement }
                                        isChecked={ 
                                            eventIds.includes(event.id) ?
                                                true
                                                :
                                                false
                                        }
                                    />))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="popup__footer-pagination">
                    <div className="item-list__bottom">
                        1 de 1
                        <a
                            className="x-reverse"
                            dangerouslySetInnerHTML={{ __html: icons.arrowLast }}
                            href="#"
                        ></a>
                        <a
                            className="x-reverse"
                            dangerouslySetInnerHTML={{ __html: icons.arrow }}
                            href="#"
                        ></a>
                        <a
                            dangerouslySetInnerHTML={{ __html: icons.arrow }}
                            href="#"
                        ></a>
                        <a
                            dangerouslySetInnerHTML={{ __html: icons.arrowLast }}
                            href="#"
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

EventsPopUp.propTypes = {

}

export default EventsPopUp
