import React from 'react'

const Conekta = ({ publicKey, token, customer_id, url, authenticityToken }) => {
  window.ConektaCheckoutComponents.Card({
    targetIFrame: "#conektaIframeContainer",
    checkoutRequestId: token, // // Checkout request ID, es el mismo ID generado en el paso 1
    onClose: function() {
      window.location.reload();
    },
    publicKey: publicKey, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
    options: {
      styles: {
        // inputType modifica el diseño del Web Checkout Tokenizer
        // inputType: 'basic' // 'basic' | 'rounded' | 'line'
        inputType: 'basic',
        // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
        // buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
        buttonType: 'basic',
        //Elemento que personaliza el borde de color de los elementos            
        states: {
          empty: {
            borderColor: '#FFAA00' // Código de color hexadecimal para campos vacíos
          },
          invalid: {
            borderColor: '#FF00E0' // Código de color hexadecimal para campos inválidos
          },
          valid: {
            borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
          }
        }
      },
      languaje: 'es', // 'es' Español | 'en' Ingles
      //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
      button: {
        colorText: '#FFFFFF', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
        text: 'Agregar Tarjeta', //Nombre de la acción en el botón
        backgroundColor: '#FFE9EC' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
      },
      //Elemento que personaliza el diseño del iframe
      iframe: {
        colorText: '#021D49',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
        backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
      }
    },
    onCreateTokenSucceeded: async function (token) {      
      const params = new FormData();
      params.append('token_id', token.id);
      params.append('customer_id', customer_id);
      params.append('authenticity_token', authenticityToken);
      

      const options =  {
        method: 'POST',
        body: params,
      };

      const response = await fetch(url, options);

      //const data = await respondeD.json();

      console.log(response)
      //console.log(data)
      document.location.reload();
    },
    onCreateTokenError: function(error) {
      console.log(error)
    }
  })

  return (
      <></>
  )
}

export default Conekta
