import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UserOptions = ({ name, lastName, pictureUrl, logout }) => {
  const [dropShow, setDropShow] = useState(false);

  return (
    <div className="top-nav__user-options">
      <span>{`${name} ${lastName}`}</span>

      <button type="button" className="dropdown-toggle mx-3" onClick={() => setDropShow(!dropShow)} />
      
      <div className={dropShow ? 'top-nav__user-options__menu' : 'd-none'}>
        <div  dangerouslySetInnerHTML={{__html: logout}} />
      </div>
    
      <img src={pictureUrl} alt="Foto de perfil" />
    </div>
  )
};

UserOptions.defaultProps = {
  pictureUrl: 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'
}

UserOptions.propTypes = {
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
}

export default UserOptions;
