import React, { useState } from 'react';
import PopUp from '../layout/PopUp';

const PopUpEditEventSlideshow = ({ event, url, authenticityToken }) => {
  const pencilIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1067 6.07161L9.92833 4.89328L2.16667 12.6549V13.8333H3.345L11.1067 6.07161ZM12.285 4.89328L13.4633 3.71495L12.285 2.53661L11.1067 3.71495L12.285 4.89328ZM4.035 15.4999H0.5V11.9641L11.6958 0.768281C11.8521 0.612055 12.064 0.524292 12.285 0.524292C12.506 0.524292 12.7179 0.612055 12.8742 0.768281L15.2317 3.12578C15.3879 3.28205 15.4757 3.49398 15.4757 3.71495C15.4757 3.93592 15.3879 4.14784 15.2317 4.30411L4.03583 15.4999H4.035Z" fill="#FCFCFC"/>
    </svg>
  );

  const [showPopUp, setShowPopUp] = useState(false);

  const closePopUp = () => {
    setShowPopUp(false);
  }

  const handleClosePopUp = (e) => {
    e.preventDefault();
    closePopUp();
  }

  const handleOpenPopUp = (e) => {
    e.preventDefault();
    setShowPopUp(true);
  }

  return (
    <>
      <a class="btn btn-warning" onClick={ handleOpenPopUp }>
        { pencilIcon }
      </a>
      {
      showPopUp &&
      <PopUp popUpClass="--confirm-commerce" closeAction={ closePopUp }>
        <div className="popup__header">
          <div>Editar evento</div>
          <div className="popup__header__close">
            <button
              className="popup__header__close__button --red-button"
              onClick={ () => setShowPopUp(false) }
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z" fill="#021D49"/>
              </svg>
            </button>
          </div> 
        </div>
        <form method="post" autoComplete="off" action={ url } >
          <div className="popup__body text-start p-3 py-4">
            <input
                type="hidden"
                name="authenticity_token"
                value={ authenticityToken }                
            />
            <input type="hidden" name="_method" value="PUT" />
            <div className="mb-3">
              <label className="form-label">Orden</label>
              <input
                type="text"
                name="event_slideshow[order]"
                className="form-control mb-3"
                placeholder="Orden al mostrar"
                defaultValue={ event.order }
              ></input>
            </div>
          </div>
          <div className="popup__footer justify-content-center flex-row-reverse">
            <button type="submit" className="btn btn-publish mx-3">Agregar</button>
            <button
              className="btn btn-return"
              onClick={ handleClosePopUp }
            >Cancelar</button>
          </div>
        </form>
      </PopUp>
      }
    </>
  );
};

export default PopUpEditEventSlideshow;
