import React, { useState } from 'react'

const StickyInfo = ({ description }) => {
    const [showDescription, setShowDescription] = useState(false);

    const InfoIcon = (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.41468 22.3804H28.482V3.30351H3.68203V24.5822L6.41468 22.3804ZM7.48728 25.5599L0.582031 31.124V1.71377C0.582031 1.29214 0.745334 0.887783 1.03602 0.589648C1.3267 0.291514 1.72095 0.124023 2.13203 0.124023H30.032C30.4431 0.124023 30.8374 0.291514 31.128 0.589648C31.4187 0.887783 31.582 1.29214 31.582 1.71377V23.9702C31.582 24.3918 31.4187 24.7962 31.128 25.0943C30.8374 25.3924 30.4431 25.5599 30.032 25.5599H7.48728ZM14.532 17.6112H17.632V20.7907H14.532V17.6112ZM10.7609 9.3652C10.9623 8.33154 11.4457 7.37778 12.1549 6.61491C12.8642 5.85204 13.7701 5.31143 14.7672 5.05598C15.7643 4.80053 16.8116 4.84075 17.7873 5.17196C18.763 5.50317 19.6269 6.11175 20.2786 6.92688C20.9302 7.74202 21.3428 8.73019 21.4682 9.7764C21.5937 10.8226 21.4269 11.8839 20.9872 12.8366C20.5476 13.7894 19.8532 14.5946 18.9848 15.1584C18.1164 15.7222 17.1098 16.0215 16.082 16.0215H14.532V12.842H16.082C16.5224 12.8419 16.9536 12.7137 17.3256 12.4721C17.6977 12.2305 17.9951 11.8855 18.1835 11.4773C18.3718 11.0691 18.4433 10.6144 18.3895 10.1662C18.3357 9.71795 18.159 9.29459 17.8798 8.94537C17.6006 8.59615 17.2304 8.33541 16.8124 8.19351C16.3944 8.05161 15.9457 8.03438 15.5185 8.14381C15.0913 8.25325 14.7032 8.48485 14.3993 8.81168C14.0954 9.13851 13.8883 9.54712 13.802 9.98997L10.7609 9.3652Z" fill="#F04D5E"/>
        </svg>
    );

    return (
        <div className="sticky-info">
            {
                showDescription &&
                    <div className="sticky-info__description" dangerouslySetInnerHTML={{ __html: description }}>
                    </div>
            }
            <div onClick={() => setShowDescription(!showDescription)} className="sticky-info__button">
                { InfoIcon }
            </div>
        </div>
    )
}

StickyInfo.propTypes = {

}

export default StickyInfo
