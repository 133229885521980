import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const PopUp = ({ children, popUpClass, closeAction }) => {
  const popUpArea = useRef();
  const popUpContent = useRef();

  const handleCloseOutsidePopUp = (e) => {
    if (e.target.contains(popUpContent.current)) {
      closeAction();
    }
  }

  return (
    <>
      <div className='popup-close-area'></div>
      
      <div className="popup-container" onClick={ handleCloseOutsidePopUp } ref={ popUpArea }>
        <div className={`popup ${popUpClass}`} ref={ popUpContent }>
          { children }
        </div>
      </div>
    </>
  );
};


PopUp.propTypes = {
  popUpClass: PropTypes.string,
};

PopUp.defaultProps = {
    popUpClass: '',
};

export default PopUp;