import React, { useEffect, useState } from 'react';
import { usePopup } from '../hooks/usePopup';
import { useElementList } from '../hooks/useElementList';
import EventsPopUp from './EventsPopUp';
import EventsTr from './EventsTr';
import { element } from 'prop-types';

const Events = ({ icons, links, authenticityToken, urls, events, railsEventIds }) => {
    const { popUpState, showPopUp, closePopUp } = usePopup();

    const handleShowPopUp = (e) => {
        e.preventDefault();
        showPopUp();
    }

    const { addElement, removeElement, elementList } = useElementList();
    const [eventIds, setEventIds] = useState(railsEventIds);

    console.log(eventIds)
    useEffect(() => {
        setEventIds(elementList.map(element => element.id));
    }, [ elementList ])


    return (
        <div className="simple-container">
            <div className="item-list__top justify-content-end">
                <a href="#" onClick={handleShowPopUp}><i className="plus-icon" /> Añadir nuevo</a>
            </div>

            <table className="table-general">
                <thead>
                    <tr>
                        <th></th>
                        <th>Título</th>
                        <th>Categoria</th>
                        <th>Precio</th>
                        <th>Fecha</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        elementList.length > 0 && 
                            elementList.map(element => (
                                <EventsTr
                                    key={element.id}
                                    event={element}
                                    removeElement={ removeElement }
                                />
                            ))
                    }
                </tbody>
            </table>
    
            {
                elementList.length === 0 &&
                    <div className="empty-table">
                        <p className="empty-table__description">No existen eventos que mostrar</p>
                        <button
                            className="btn btn-outline-primary empty-table__button"
                            onClick={handleShowPopUp}
                        >Crear evento</button>
                    </div>
            }
            

            <div className="item-list__bottom">
                1 de 1
                <a
                    className="x-reverse"
                    dangerouslySetInnerHTML={{ __html: icons.arrowLast }}
                    href="#"
                ></a>
                <a
                    className="x-reverse"
                    dangerouslySetInnerHTML={{ __html: icons.arrow }}
                    href="#"
                ></a>
                <a
                    dangerouslySetInnerHTML={{ __html: icons.arrow }}
                    href="#"
                ></a>
                <a
                    dangerouslySetInnerHTML={{ __html: icons.arrowLast }}
                    href="#"
                ></a>
            </div>
            {
                popUpState &&
                    <EventsPopUp
                        closePopUp={ closePopUp }
                        icons={ icons }
                        addElement={ addElement }
                        removeElement={ removeElement }
                        links={ links }
                        authenticityToken={ authenticityToken }
                        urls={ urls }
                        events={ events }
                        eventIds={ eventIds }
                    />
            }
        </div>
    );
}

export default Events;