import { useState } from 'react';

const useElementList = (initElements = []) => {
    const [elementList, setElementList] = useState(initElements);

    const addElement = (element) => {
        console.log('Add validation for add element table');
        setElementList([
            ...elementList,
            element
        ]);
    }

    const removeElement = (id) => {
        const newElementList = elementList.filter(element => element.id !== id);
        setElementList(newElementList);
    }

    const removeElementByIndex = (position) => {
        const newElementList = elementList.filter((value, index, element) => index !== position);
        setElementList(newElementList);
    }

    const changeElementList = (index, name, value) => {
        const newElementList = elementList;
        newElementList[index][name] = value;
        setElementList([...newElementList]);
    };

    return {
        elementList,
        addElement,
        removeElement,
        removeElementByIndex,
        changeElementList
    }
}

export { useElementList }