import React, { useState, useEffect } from 'react'

const useUrlParams = (params = {}) => {
    const [urlSearchData, setUrlSearchData] = useState(params);
    const [urlParams, setUrlParams] = useState('');

    const generateUrlParams = () => {
      const urlParameters = Object.entries(urlSearchData).map(e => e.join('=')).join('&');
      setUrlParams(urlParameters);
    }

    const selectOption = (target) => {
      if (urlSearchData.hasOwnProperty(target.name)) {
        setUrlSearchData(urlSearch => ({
          ...urlSearch,
          [target.name]: target.value
        }));
      }
    }

    const cleanOption = (name) => {
      setUrlSearchData(urlSearch => ({
        ...urlSearch,
        [name]: ''
    }))};

    useEffect(() => {
      generateUrlParams(urlSearchData);
    }, [urlSearchData]);


    return {
      urlParams,
      urlSearchData,
      selectOption,
      cleanOption,
    };
}

export { useUrlParams }
