import React, { useState } from "react";
import PropTypes from "prop-types";

const CallToAction = ({cta, cta2, icons, allowedCTAs}) => {
  const allCTAs = ['Llamar', 'Ubicación', 'WhatsApp', 'Informes', 'Consultar', 'Reservar', 'Cotizar']
  const [preview, setPreview] = useState(cta ?? allCTAs[0]);
  const [preview2, setPreview2] = useState(cta2 ?? allCTAs[1]);

  return (
    <>
      <div className="col-12 col-lg-6 mb-3">
        <p className="directory-tab__filters__subtitle mb-2 mt-0">Botón CTA Principal</p>
        <select
          className="form-control"
          defaultValue={cta ? cta : preview}
          onChange={e => setPreview(e.target.value)}
          name="commerce[cta]"
        >

          {allCTAs.map(i => (
            <option
              value={allowedCTAs.includes(i) ? i : null}
              disabled={!allowedCTAs.includes(i)}
              key={`${i}1`}
            >
              {i} {!allowedCTAs.includes(i) ? ' - No disponible en tu plan' : ''}
            </option>))}
        </select>
      </div>
      <div className="col-12 col-lg-4 offset-lg-1 mb-3">
        <p className="directory-tab__filters__subtitle mb-2 mt-0">Vista previa</p>
        <button type="button" className="directory-tab__preview-btn">{preview}</button>
      </div>
      <p>Botón principal de gran tamaño que se ubica en la página principal de tu negocio y en el directorio</p>

      <div className="col-12 col-lg-6 mb-3">
        <p className="directory-tab__filters__subtitle mb-2 mt-0">Botón CTA Secundario</p>
        <select
          className="form-control"
          defaultValue={cta2 ? cta2 : preview2}
          onChange={e => setPreview2(e.target.value)}
          name="commerce[cta2]"
        >
          {allCTAs.map(i => (
            <option
              value={allowedCTAs.includes(i) ? i : null}
              disabled={!allowedCTAs.includes(i)}
              key={`${i}2`}
            >
              {i} {!allowedCTAs.includes(i) ? ' - No disponible en tu plan' : ''}
            </option>))}
        </select>
      </div>
      <div className="col-12 col-lg-4 offset-lg-1 mb-3">
        <p className="directory-tab__filters__subtitle mb-2 mt-0">Vista previa</p>
        <button type="button" className="directory-tab__preview-btn --preview-secondary"><img src={icons[preview2]} alt={preview2} /></button>
      </div>
      <p>Botón secundario de pequeño tamaño que se ubica en la página principal de tu negocio</p>

      {allowedCTAs.length > 2 && (
        <>
          <p className="directory-tab__filters__subtitle mb-2 mt-0">Botón CTA Terciario</p>
          <p>En el icono (···), se activarán los botones restantes en tu plan </p>
          <div>
            {allowedCTAs.filter(x => ![preview, preview2].includes(x)).map(i => (
              <button type="button" className="directory-tab__preview-btn --preview-tertiary">
                <img src={icons[i]} alt={i} />
                {i}
              </button>
            ))}
          </div>
        </>
      )}
    </>
  )
};

CallToAction.propTypes = {
  cta: PropTypes.string,
  cta2: PropTypes.string,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
  allowedCTAs: PropTypes.arrayOf(PropTypes.string),
};

CallToAction.defaultProps = {
  cta: 'Llamar',
  cta2: 'Ubicación',
  allowedCTAs: [],
}

export default CallToAction;
