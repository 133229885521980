import React from 'react';

const PopUpConfirm = ({ text, action, closePopUp }) => {

    const handleStartAction = () => {
        action();
    }
    const handleClosePopUp = () => {
        closePopUp();
    }

    return (
        <div className="popup-container">
            <div className="popup popup-confirm">
                <div className="popup__header">
                    <div></div>
                    <div className="popup__header__close">
                        <button
                            className="popup__header__close__button"
                            onClick={ handleClosePopUp }
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div> 
                </div>
                <div className="popup-confirm__body">
                    <h2>{text}</h2>
                </div>
                <div className="popup-confirm__footer">
                    <button
                        className="btn btn-outline-primary mr-4"
                        onClick={ handleClosePopUp }
                    >Cancelar</button>
                    <button
                        className="btn btn-primary"
                        onClick={ handleStartAction }
                    >Aceptar</button>
                </div>
            </div>
        </div>
    );
}

export default PopUpConfirm;
