import autocomplete from 'js-autocomplete';

const autocompleteSearch = (searchData, searchInput, name) => {
  const curedData = searchData.map(i => i.replaceAll('"', '&quot;'));
  
  if (curedData && searchInput) {
    new autocomplete({
      selector: searchInput,
      minChars: 1,
      source: (term, suggest) => {
        // Search term typed by user
        term = term.toLowerCase();

        // Items that match provided term
        const matches = [];
        for (let i = 0; i < curedData.length; i++)
            if (~curedData[i].toLowerCase().indexOf(term)) matches.push(curedData[i]);

        // Create suggestion elements
        suggest(matches);
      },
      onSelect: (e, term) => {
        window.location.search = `?${name}=${encodeURIComponent(term)}`
      },
    });
  }
};

export default autocompleteSearch;