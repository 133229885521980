import React from 'react'
import EventSlideshowCard from '../components/EventSlideshowCard'

const EventsSlideshow = ({ events, authenticityToken }) => {
    return (
        <div className="event-slideshow__container">
            {
                events.length > 0 ?
                    <div className="event-slideshow__cards">
                        {events.map(event => <EventSlideshowCard event={ event } authenticityToken={ authenticityToken } key={ event.id }/>)}
                    </div>
                    :
                    <div className="event-slideshow__empty">
                        <h2>No has agregado ningun evento</h2>
                    </div>
            }
        </div>
    )
}

export default EventsSlideshow
