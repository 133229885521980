import React, { useState } from "react";
import Select from "react-select";
import PreviewImageImprove from "../PreviewImageImprove";
import DirectVideoUpload from "./DirectVideoUpload";
import VideoPlayer from "./VideoPlayer";

const imgIcon = (
  <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.2493 0.75H3.74935C2.33268 0.75 0.916016 2.16667 0.916016 3.58333V23.4167C0.916016 24.1681 1.21453 24.8888 1.74588 25.4201C2.27723 25.9515 2.9979 26.25 3.74935 26.25H29.2493C30.666 26.25 32.0827 24.8333 32.0827 23.4167V3.58333C32.0827 2.16667 30.666 0.75 29.2493 0.75ZM6.58268 20.5833L11.541 14.2083L15.0827 18.4583L20.041 12.0833L26.416 20.5833H6.58268Z" fill="#FFD1D2"/>
  </svg>
);

const uploadIcon = (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.667969 7.5H2.33464V11.25H15.668V7.5H17.3346V11.25C17.3346 12.0825 16.593 12.75 15.668 12.75H2.33464C1.41797 12.75 0.667969 12.0825 0.667969 11.25V7.5ZM9.0013 0L4.38464 4.095L5.56797 5.16L8.16797 2.8125V9.75H9.83463V2.8125L12.443 5.16L13.6263 4.0875L9.0013 0Z" fill="#575757"/>
  </svg>
);

const btnIcon = (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 13V7H0.5L7.5 0L14.5 7H10.5V13H4.5ZM0.5 17V15H14.5V17H0.5Z" fill="#E31519"/>
  </svg>
);

const streamBaseUrl = 'https://d318ayjbtf00wh.cloudfront.net/stream_720-';

const OttVideosForm = ({ currentValues, tags, statuses }) => {
  const [currentTags, setCurrentTags] = useState(
    currentValues.tags.map((tag) => ({ value: tag.id, label: tag.name })) ?? []
  );

  const [allowSave, setAllowSave] = useState(true);

  const { signedId, videoUrl, thumbnail, title, description, publishDate, slug, streamKey, streamUrl } =
    currentValues;

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-end">
        <div className="col-2 text-right">
          <select
            className="form-select"
            defaultValue={currentValues.status}
            name="ott_video[status]"
          >
            <option value="Pending" selected hidden>
              Elegir status
            </option>
            {Object.keys(statuses).map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-6 mb-3 video-aspect">
        {!streamKey && (
          <>
            <label name="ott_video[video]" className="form-label">
              Subir video
            </label>
            <DirectVideoUpload
              name="ott_video[video_signed_id]"
              videoData={{ signedId, videoUrl }}
              addClass="ott-video__video"
              onStart={() => setAllowSave(false)}
              onEnd={() => setAllowSave(true)}
            />
          </>
        )}
        {streamKey && (
          <>
            <p className="form-label">Transmisión</p>
            <VideoPlayer 
              autoplay={false}
              controls={true}
              sources={[{ src: streamUrl ? streamUrl : `${streamBaseUrl}${streamKey}` }]}
            />
          </>
        )}
      </div>

      <div className="col-6 mb-3 mt-4">
        <label name="ott_video[thumbnail]" className="form-label">
          Miniatura del video
        </label>

        <div className="ott-video__thumb-area">
          <div className="ott-video__thumb-area__content">
              {imgIcon}
              <span>
                {btnIcon}
                Subir imagen
              </span>
              <p>
                {uploadIcon}
                Arrastra aqui el archivo o selecciona uno
              </p>
          </div>
          <PreviewImageImprove
            initialImage={thumbnail}
            inputData={{ model: "ott_video", name: "thumbnail" }}
            aditionalClassName="ott-video__thumbnail"
          />
        </div>
      </div>

      <div className="col-6">
        <div className="mb-3">
          <label name="ott_video[title]" className="form-label">
            Titulo del Video
          </label>
          <input
            defaultValue={title}
            type="text"
            name="ott_video[title]"
            className="form-control"
            placeholder="Este es el titulo del video"
          />
        </div>

        <div className="mb-3">
          <label name="ott_video[description]" className="form-label">
            Descripción
          </label>
          <textarea
            defaultValue={description}
            name="ott_video[description]"
            rows="3"
            className="form-control"
            placeholder="Esta es la descripción del video"
          />
        </div>
      </div>

      <div className="col-6">
        <div className="row mb-3">
          <div className={slug ? "col-5" : "col-12"}>
            <label name="ott_video[publish_date]" className="form-label">
              Fecha de Publicación
            </label>
            <input
              defaultValue={publishDate}
              type="datetime-local"
              name="ott_video[publish_date]"
              className="form-control"
            />
          </div>
          {slug && (
            <div className="col-7">
              <label name="ott_video[slug]" className="form-label">
                Slug
              </label>
              <input
                defaultValue={slug}
                name="ott_video[slug]"
                className="form-control"
              />
            </div>
          )}
        </div>

        <div className="mb-3">
          <label name="tags" className="form-label">
            Tags
          </label>

          {currentTags.map((t) => (
            <input type="hidden" value={t.value} name="ott_video[ott_tag_ids][]" />
          ))}

          <Select
            isMulti
            name="tags"
            placeholder="Buscar o asignar tags"
            options={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
            className="basic-multi-select"
            classNamePrefix="Selecciona uno o mas tags"
            value={currentTags}
            onChange={(e) => setCurrentTags(e)}
          />
        </div>
      </div>
      <button className="btn btn-publish" disabled={!allowSave}>
        Guardar
      </button>
    </div>
  );
};

export default OttVideosForm;
