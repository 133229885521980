import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
    GoogleMap,
    useLoadScript,
    Marker
} from '@react-google-maps/api'

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getZipCode,
    getDetails,
} from 'use-places-autocomplete';

import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const libraries = ['places'];

const mapContainerStyles = {
    width: '100%',
    height: '100%',
}

const apiKey = 'AIzaSyAIlg9Ke0MneV2byVvUb3hCN-l8U6kxbAc';


const GoogleMapsApi = ({ railsModel, railsFields }) => {
    const [center, setCenter] = useState(railsFields.coordinates.value 
        ? JSON.parse(railsFields.coordinates.value)
        : {
            lat: 19.0407246,
            lng: -98.2058503,
        })

    const [markers, setMarkers] = useState(null);

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });
    
    const handleOnMapClick = useCallback((e) => {
        setMarkers({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        });
    }, [markers]);
    
    const handleDragMarker = (e) => {
        setMarkers({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        });
    }

    const mapRef = useRef();
    const handleMapLoad = useCallback(
        (map) => {
            mapRef.current = map;

            const railsCoordinates = railsFields.coordinates.value;

            if(railsCoordinates) {
                const {lat, lng} = JSON.parse(railsFields.coordinates.value);
                setMarkers({lat, lng});
                panTo({lat, lng});
            }
        },
        [],
    )

    const panTo = useCallback(
        ({lat, lng}) => {
            mapRef.current.panTo({lat, lng});
            mapRef.current.setZoom(15);
        },
        [],
    );

    if (loadError) return 'error loading map'
    if (!isLoaded) return 'Loading maps'

    return (
        <div>
            <Search
                panTo={panTo}
                setMarkers={setMarkers}
                railsModel={ railsModel }
                railsFields={ railsFields }
                marker={markers}
            />

            <div className="googlemaps-area">
                <strong>Selecciona la ubicación en el mapa</strong>
                <div className="map-area">
                    <GoogleMap
                        mapContainerStyle={mapContainerStyles}
                        zoom={ 15 }
                        center={ center }
                        onClick={ handleOnMapClick }
                        onLoad={ handleMapLoad }
                    >
                        {
                        markers &&
                            <Marker
                                position={{ lat: markers.lat, lng: markers.lng }}
                                draggable
                                onDragEnd={handleDragMarker}
                            />
                        }
                    </GoogleMap>
                </div>
            </div>
            <input type="hidden" name={`${railsModel}[${railsFields.coordinates.name}]`} value={markers ? JSON.stringify(markers) : ''} />
        </div>
    )
}

const Search = React.memo(({ panTo , setMarkers, railsModel, railsFields, marker }) => {
    const [geocodeLoading, setGeocodeLoading] = useState(false);
    const [geocodeMessage, setGeocodeMessage] = useState({
        type: null,
        message: null
    });

    const chandeGeocodeMessage = (type, message) => {
        setGeocodeMessage({
            type: type,
            message: message,
        })
    }

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 19.0407246, lng: () => -98.2058503 },
            radius: 100 * 1000,
        },
    });

    useEffect(async () => {
        const railsCoords = railsFields.coordinates.value ? JSON.parse(railsFields.coordinates.value) : null;


        if (
            marker &&
            ((
                railsCoords && 
                railsCoords.lat !== marker.lat &&
                railsCoords.lng !== marker.lng
            )|| !railsCoords)
            
        ) {
            try {
                setGeocodeLoading(true);
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.lat},${marker.lng}&key=${apiKey}`);
                const address = await response.json();
                const details = await getDetails({
                    placeId: address.results[0].place_id,
                });
                const zipCode = await getZipCode(address.results[0], false);
                //console.log(details)
                //console.log(address.results[0].formatted_address)
                setValue(address.results[0].formatted_address, false)
                setPostalCode(zipCode ? zipCode : '');
                chandeGeocodeMessage('success', 'Dirección actualizada');
            } catch (error) {
                chandeGeocodeMessage('error', 'No se pudo obtener la dirección del marcador');
                throw new Error(`Something failed on reverse geocode`);
            } finally {
                setGeocodeLoading(false);
            }
        }
    }, [marker])

    const [postalCode, setPostalCode] = useState(railsFields.postalCode.value || '');

    useEffect(() => {
        if (railsFields.place.value) {
            setValue(railsFields.place.value, false);
            clearSuggestions();    
        }
    }, [])

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();
    
        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });
            const zipCode = await getZipCode(results[0], false);
            const details = await getDetails({
                placeId: results[0].place_id,
                fields: ["vicinity"],
            });
            setPostalCode(zipCode ? zipCode : '');

            setMarkers({lat, lng});
        } catch (error) {
            console.log("Error on google geocode:", error);
        }
    };

    

    return (
        <> 
            <div className="row mb-3">
                <div className="col-md-8">                
                    <label  className='form-label' htmlFor={`${railsModel}_${railsFields.place.name}`}> Dirección</label>
                    <Combobox
                        onSelect={ handleSelect }
                        >
                        <ComboboxInput
                            className="form-control"
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                            disabled={!ready}
                            placeholder="Ingresa una direccion"
                            name={`${railsModel}[${railsFields.place.name}]`}
                            id={`${railsModel}_${railsFields.place.name}`}
                            autoComplete="off"
                        />
                        <ComboboxPopover>
                            <ComboboxList>
                            { status === "OK" &&
                                data.map(({place_id, description}) => <ComboboxOption key={place_id} value={description} />)
                            }
                            </ComboboxList>
                        </ComboboxPopover>
                    </Combobox>
                </div>
                <div className="col-md-4">
                    <label className='form-label' htmlFor={`${railsModel}_${railsFields.postalCode.name}`}>Código postal</label>
                    <input
                        type='text'
                        className='form-control'
                        id={`${railsModel}_${railsFields.postalCode.name}`}
                        name={`${railsModel}[${railsFields.postalCode.name}]`}
                        placeholder="Ingresa el código postal"
                        value={postalCode}
                        onChange={({target}) => setPostalCode(target.value)}
                        autoComplete="off"
                    />
                </div>
            </div>
            {
                geocodeLoading ?
                    <div className="d-flex flex-row  align-items-center text-primary  mb-3"> 
                        <div className="spinner-grow spinner-grow-sm" role="status">
                            <span className="sr-only"></span>
                        </div>
                        <p className="m-0 mx-2">Buscando ubicacion</p>
                    </div>
                    :
                    <>
                    {
                    geocodeMessage.type === "success" &&
                        <div className="d-flex flex-row text-success  align-items-center mb-3"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                            </svg>
                            <p className="m-0 mx-2">{ geocodeMessage.message }</p>
                        </div>
                    }{
                    geocodeMessage.type === "error" &&
                        <div className="d-flex flex-row text-danger  align-items-center mb-3"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                            </svg>
                            <p className="m-0 mx-2">{ geocodeMessage.message }</p>
                        </div>
                    }
                    </>
            }
        </>
    )

});

GoogleMapsApi.default = {
    setMarkerEnable: false
}

GoogleMapsApi.propTypes = {
    setMarkerEnable: PropTypes.bool
}

export default GoogleMapsApi;
