import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { useState } from "react";

const RichText = ({ initContent, name, imageUploadUrl }) => {
  const [content, setContent] = useState(initContent);
  
  return (
    <>
      <Editor
        // onInit={(evt, editor) => (editorRef.current = editor)}
        onChange={({ target }) => setContent(target.getContent())}
        initialValue={content}
        remove_script_host={false}
        convert_urls={false}
        relative_urls={false}
        init={{
          height: 500,
          images_upload_url: imageUploadUrl,
          menubar: false,
          automatic_uploads: true,
          file_picker_types: "image",
          images_file_types: "jpg,png,jpeg,webp",
          block_unsupported_drop: false,
          convert_urls: false,
          relative_urls: false,
          plugins: `
            advlist hr autolink lists link image charmap print preview anchor
            searchreplace visualblocks media code fullscreen
            insertdatetime media table paste code emoticons wordcount
            searchreplace visualblocks code fullscreen code image
          `,
          toolbar: 'blocks | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify |' + 
          'bullist numlist outdent indent hr | removeformat  | emoticons | image | link + undo redo code',
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <input type="hidden" name={name} value={content} />
    </>
  );
};

export default RichText;