import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SidebarGroup = ({ group, arrow, defaultDropdown }) => {
  const [dropdown, setDropdown] = useState(defaultDropdown);
  const [highlight, setHighlight] = useState(dropdown ? '--highlight' : '');
  const currentPath = window.location.pathname;

  const { links, icon, title } = group;

  const handleDropDown = () => {
    setDropdown(!dropdown);
    setHighlight(!dropdown ? '--highlight' : '');
  };

  return (
    <>
      <div className={`sidebar__menu__item ${highlight}`} onClick={handleDropDown}>
        <span dangerouslySetInnerHTML={{ __html: icon }} className="sidebar__menu__item__icon" />
        <span>{title}</span>
        <span dangerouslySetInnerHTML={{ __html: arrow }} className="sidebar__menu__item__arrow" />
      </div>

      {dropdown
        && (
        <div className="sidebar__menu__dropdown">
          {Object.keys(links).map((link, index) => (
            <a href={links[link]} key={index} className={currentPath.indexOf(links[link]) > -1 ? '--highlight-link' : ''}>
              {link}
            </a>
          ))}
        </div>
        )}
    </>
  );
};

SidebarGroup.propTypes = {
  group: PropTypes.shape({
    links: PropTypes.objectOf(PropTypes.string).isRequired,
    icon: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  arrow: PropTypes.string.isRequired,
  defaultDropdown: PropTypes.bool.isRequired,
};

export default SidebarGroup;
