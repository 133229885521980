import autocomplete from 'js-autocomplete';

const autocompleteSearchUrl = (searchData, searchInput, name) => {
  const curedData = searchData
  
  if (curedData && searchInput) {
    new autocomplete({
      selector: searchInput,
      minChars: 1,
      source: (term, suggest) => {
        // Search term typed by user
        term = term.toLowerCase();

        // Items that match provided term
        const matches = [];
        for (let i = 0; i < curedData.length; i++)
            if (~curedData[i].name.toLowerCase().indexOf(term)) matches.push(`${curedData[i].id} - ${curedData[i].name}`);
        // Create suggestion elements
        suggest(matches);
      },
      onSelect: (e, term) => {
        const id = term.split(' - ')[0]
        const element = curedData.find(item => item.id == id);
        window.location = element.url
      },
    });
  }
};

export default autocompleteSearchUrl;