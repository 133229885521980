import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PasswordInput from './PasswordInput';

const PasswordChange = ({ newUser }) => {
  const [change, setChange] = useState(false);

  return (
    <div className="password-change">
      <label className="form-label">
        Contraseña
      </label>

      <PasswordInput name="user[password]" disable={!newUser} />

      {!newUser && <button type="button" className="password-change__toggle" onClick={() => setChange(true)}>Cambiar</button> }

      {change && (
        <div className="password-change__overlay">
          <div className="password-change__overlay__change-pass">
            <h2>Cambiar contraseña</h2>
            <button type="button" onClick={() => setChange(false)}>
              <i className="fas fa-times-circle" />
            </button>

            <label className="control-label">
              Nueva contraseña
              <PasswordInput name="user[password]" />
            </label>

            <label className="control-label">
              Repetir contraseña
              <PasswordInput name="user[password_confirmation]" />
            </label>

            <div className="password-change__overlay__change-pass__buttons">
              <button type="button" onClick={() => setChange(false)}>Cancelar</button>
              <button type="submit">Aceptar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PasswordChange.propTypes = {
  newUser: PropTypes.bool.isRequired,
};

export default PasswordChange;
