import React, { useState } from "react";
import ActiveStorageProvider from "react-activestorage-provider";

// Icons
const trashIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0265 3.33339H13.2316V4.61544H11.9496V12.9488C11.9496 13.1188 11.8821 13.2818 11.7618 13.4021C11.6416 13.5223 11.4786 13.5898 11.3086 13.5898H2.33421C2.1642 13.5898 2.00115 13.5223 1.88094 13.4021C1.76072 13.2818 1.69318 13.1188 1.69318 12.9488V4.61544H0.411133V3.33339H3.61626V1.41031C3.61626 1.2403 3.6838 1.07725 3.80401 0.957039C3.92423 0.836824 4.08728 0.769287 4.25729 0.769287H9.38549C9.5555 0.769287 9.71855 0.836824 9.83877 0.957039C9.95898 1.07725 10.0265 1.2403 10.0265 1.41031V3.33339ZM10.6675 4.61544H2.97524V12.3077H10.6675V4.61544ZM4.89831 6.53852H6.18036V10.3847H4.89831V6.53852ZM7.46242 6.53852H8.74447V10.3847H7.46242V6.53852ZM4.89831 2.05134V3.33339H8.74447V2.05134H4.89831Z"
      fill="#6E7191"
    />
  </svg>
);
const videoIcon = (
  <svg width="59" height="43" viewBox="0 0 59 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.2273 0C41.7076 0 42.9091 1.204 42.9091 2.6875V13.975L56.8894 4.16563C57.4955 3.741 58.3322 3.88881 58.7586 4.49887C58.9142 4.72462 59 4.99338 59 5.2675V37.7325C59 38.4743 58.3993 39.0763 57.6591 39.0763C57.3829 39.0763 57.1147 38.9903 56.8894 38.8344L42.9091 29.025V40.3125C42.9091 41.796 41.7076 43 40.2273 43H2.68182C1.20145 43 0 41.796 0 40.3125V2.6875C0 1.204 1.20145 0 2.68182 0H40.2273ZM37.5455 5.375H5.36364V37.625H37.5455V5.375ZM21.4545 10.75L32.1818 21.5H24.1364V32.25H18.7727V21.5H10.7273L21.4545 10.75ZM53.6364 13.0102L42.9091 20.5352V22.4648L53.6364 29.9898V13.0075V13.0102Z"
      fill="#E31519"
      fill-opacity="0.2"
    />
  </svg>
);

const PreviewVideo = ({ aditionalClassName, fileUrl, recordId }) => {
  const submitBtn = document.querySelectorAll("button.btn-publish")[0];

  const [finished, setFinished] = useState(false);
  const [videoUrl, setVideoUrl] = useState(fileUrl);

  const handleRemoveImage = (e) => {
    e.preventDefault();
    setVideoUrl(null);
  };

  return (
    <div className={`${aditionalClassName} preview-file`}>
      {videoUrl ? (
        <>
          <div className="video-area">
            <button
              className="preview-file__delete-button"
              onClick={handleRemoveImage}
            >
              {trashIcon}
            </button>
            <video src={videoUrl} controls />
          </div>
        </>
      ) : (
        <ActiveStorageProvider
          endpoint={{
            path: `/admin/articles/${recordId}`,
            model: "Article",
            attribute: "video",
            method: "PUT",
          }}
          onSubmit={(article) => this.setState({ video: article.video })}
          render={({ handleUpload, uploads, ready }) => (
            <div className="video-container">
              {!finished && uploads.length == 0 && (
                <>
                  <div className="video-container">
                    <input
                      type="file"
                      disabled={!ready}
                      className="preview-file__input-file"
                      accept=".mov, .mp4"
                      onChange={(e) => handleUpload(e.currentTarget.files)}
                    />
                    <div>{videoIcon}</div>
                    <p>Formatos aceptados: .mov | .mp4</p>
                  </div>
                </>
              )}

              {finished && (
                <>
                  <p className="px-3 text-danger">¡Listo!</p>
                  <div
                    className="preview-file__progress"
                    style={{ "--progress": "100%" }}
                  >
                    <span>100%</span>
                  </div>
                </>
              )}

              {uploads.map((upload) => {
                switch (upload.state) {
                  case "waiting":
                    submitBtn.disabled = true;
                    return (
                      <p key={upload.id} className="px-3 m-0">
                        Preparando subida del vídeo...
                      </p>
                    );
                  case "uploading":
                    return (
                      <>
                        <p>Subida en progreso:</p>
                        <div
                          className="preview-file__progress"
                          style={{
                            "--progress": `${Math.floor(upload.progress)}%`,
                          }}
                        >
                          <span>{Math.floor(upload.progress)}%</span>
                        </div>
                      </>
                    );
                  case "error":
                    console.error(upload.error);
                    return (
                      <p key={upload.id} className="px-3 m-0">
                        Ha ocurrido un error, inténtalo de nuevo
                      </p>
                    );
                  case "finished":
                    submitBtn.disabled = false;
                    submitBtn.click();
                    setFinished(true);
                    return (
                      <p key={upload.id} className="px-3 m-0">
                        Se subió {upload.file.name} correctamente
                      </p>
                    );
                }
              })}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default PreviewVideo;
