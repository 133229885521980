import React, { useState } from 'react';
import { getShortFullDate } from '../../helpers/dateFormats';
import PropTypes from 'prop-types'

const DateTimeScheduleItem = React.memo(({selectedDay, editDateTimeSchedule, index, onRemoveDay}) => {
    const scheduleTypes = [
        'Hora Inicio',
        'Hora Inicio - Hora Fin',
        'Todo el día',
    ];

    const [scheduleTime, setScheduleTime] = useState({
        opening: selectedDay.schedule_time ? selectedDay.schedule_time.opening : '',
        closing: selectedDay.schedule_time ? selectedDay.schedule_time.closing : '',
    });

    const handleChangeScheduleType = ({target}) => {
        editDateTimeSchedule(index, {
            schedule_type: parseInt(target.value)
        });
    }

    const handleChangeScheduleTime = ({target}, type) => {
        setScheduleTime(time => ({
            ...time,
            [type]: target.value,
        }));
        editDateTimeSchedule(index, {
            schedule_time: scheduleTime
        });
    }

    return (
        <tr>
            <td>{ getShortFullDate(selectedDay.schedule_day) }</td>
            <td>
            <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleChangeScheduleType}
                value={selectedDay.schedule_type}
            >
                { scheduleTypes.map((scheduleType, index) => (
                    <option value={index} key={index}>{ scheduleType }</option>
                ))}
            </select>
            </td>
            <td>
                {
                    selectedDay.schedule_type === 0 && 
                        <div className="row">
                            <div className="col">
                                <input
                                    type='time'
                                    className='form-control'
                                    onChange={(e) => { handleChangeScheduleTime(e, 'opening')}}
                                    on
                                    value={ selectedDay.schedule_time.opening }
                                />
                            </div>
                            <div className="col">
                                
                            </div>
                        </div>
                }
                {
                    selectedDay.schedule_type === 1 && 
                        <div className="row">
                            <div className="col">
                                <input
                                    type='time'
                                    className='form-control'
                                    onChange={(e) => { handleChangeScheduleTime(e, 'opening')}}
                                    value={ selectedDay.schedule_time.opening }
                                />
                            </div>
                            <div className="col">
                                <input
                                    type='time'
                                    className='form-control'
                                    onChange={(e) => { handleChangeScheduleTime(e, 'closing')}}
                                    value={ selectedDay.schedule_time.closing }
                                />
                            </div>
                        </div>
                }
                {
                    selectedDay.schedule_type === 2 && 
                        <p className="m-0">Todo el dia</p>
                }
            </td>
            <td className="remove-date" onClick={() => onRemoveDay(selectedDay.schedule_day)}>Eliminar</td>
        </tr>
    );
});

DateTimeScheduleItem.propTypes = {
    onRemoveDay: PropTypes.func.isRequired,
    selectedDay: PropTypes.object.isRequired,
    index: PropTypes.number,
    editDateTimeSchedule: PropTypes.func.isRequired,
}

export default DateTimeScheduleItem
