import React from 'react';
import { useArticleFilters } from '../../hooks/useArticleFilter';

const ArticleFilter = ({ statuses, categories, authors, types}) => {
  const {
    urlSearch,
    selectOption,
} = useArticleFilters()

  const handleSelectOption = ({ target }) => {
    selectOption(target);
  }

  return (
    <div className='d-flex'>
      <select
        className='form-select'
        onChange={ handleSelectOption}
        value={urlSearch.status}
        name="status"
      >
        <option value=''>Elegir status</option>
        { 
          Object.keys(statuses).map((value, index) => (
            <option value={value} key={index}>{value}</option>
          ))
        }
      </select>
      <select
        className='form-select mx-2'
        onChange={ handleSelectOption}
        value={urlSearch.category}
        name="category"
      >
        <option value=''>Elegir deporte</option>
        { 
          categories.map(({id, name}, index) => (
            <option value={id} key={index}>{name}</option>
          ))
        }
      </select>
      <select
        className='form-select'
        onChange={ handleSelectOption}
        value={urlSearch.author}
        name="author"
      >
        <option value=''>Elegir autor</option>
        { 
          authors.map(({id, name}, index) => (
            <option value={id} key={index}>{name}</option>
          ))
        }
      </select>
      <select
        className='form-select mx-2'
        onChange={ handleSelectOption}
        value={urlSearch.type}
        name="type"
      >
        <option value=''>Elegir tipo</option>
        { 
          types.map(({id, name}, index) => (
            <option value={id} key={index}>{name}</option>
          ))
        }
      </select>
    </div>
  )
}

ArticleFilter.propTypes = {}

export default ArticleFilter