import React, { useState, useEffect } from 'react';

const InputSuggest = ({ url, input, currentRecord, label }) => {
    const [name, setName] = useState('')
    const [suggestions, setSuggestions] = useState([]);
    const [userSelected, setUserSelected] = useState(currentRecord);

    const getRecordsByName = async (name) => {
        try {
            const response = await fetch(`${url}?name=${name}`);
            const users = await response.json();
            //setCommerceNames(users);
            //checkCommerceName();
            setSuggestions(users)
        } catch (error) {
            throw new Error(`Something failed on get users`);
        }
    }

    const handleSelectUser = (user) => {
        setUserSelected(user);
        setSuggestions([]);
        setName('');
    }

    useEffect(() => {
        if (name !== '' ) {
            getRecordsByName(name);
        } else {
            setSuggestions([]);
        }
    }, [name])

    return (
        <div class="suggestion-form">
            <input
                type="text"
                className="form-control"
                value={ name }
                onChange={ ({target}) => { setName(target.value) } }
                placeholder={ input.placeholder || 'Busca un usuario' }
            />
            { 
                userSelected &&
                    <>
                        <input
                            type="hidden"
                            value={ userSelected.id }
                            name={`${input.model}[${input.name}]`}
                        />
                        <p className="suggestion-form__info text-muted mt-1"><strong>{label}:</strong>  {userSelected.text}</p>
                    </>
            }
            <ul className="">
                {
                    suggestions.map(suggestion => (
                        <li
                            className=""
                            key={ suggestion.id }
                            onClick={ () => {handleSelectUser(suggestion)} }
                        >
                            { suggestion.text }
                        </li>
                    ))
                }
                
            </ul>
        </div>
    );
}

export default InputSuggest;
