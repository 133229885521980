import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props)
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <>
        <div data-vjs-player style={{width: '100%', height: '93%'}}>
          <video ref={(node) => { this.videoNode = node; }} className="video-js" />
        </div>
      </>
    );
  }
}
