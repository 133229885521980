import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CreateArticleContext } from '../../contexts/CreateArticleContext';

const FootballTemplate = ({
  selectPlayer,
  footballPlayers,
  scoreBoxParams,
  addPlayerToTeam,
  removePlayerToTeam
}) => {
  const { pathNames, icons } = useContext(CreateArticleContext);

  const handleSelectPlayerOne = (value, index) => {
    selectPlayer(1, value, index)
  }

  const handleSelectPlayerTwo = (value, index) => {
    selectPlayer(2, value, index)
  }

  const handleAddMorePlayers = (number) => {
    addPlayerToTeam(number);
  }

  const handleRemovePlayer = (teamNumber, index) => {
    removePlayerToTeam(teamNumber, index);
  }

  return (
    scoreBoxParams.footballTeamOne.footballTeam != null & scoreBoxParams.footballTeamTwo.footballTeam != null ?
      <>
      <p className='section-title my-4'>Plantilla de equipo</p>
        <div className='row'>
          <div className='col-md-6'>
            <p className='section-title'>Equipo/Jugador 1</p>
              {
                scoreBoxParams.footballTeamOne.footballPlayers.map((footballPlayer, index) => (
                  <div className='mb-3'>
                    <label htmlFor="disabledSelect" className="form-label">Jugador {index + 1}</label>
                    <div className='d-flex'>
                      <select id="disabledSelect"
                        className="form-select"
                        value={ footballPlayer }
                        onChange={ ({target}) => handleSelectPlayerOne(target.value, index) }
                      >
                        <option value=''>Elegir jugador</option>
                        {
                          footballPlayers.teamOne.map(({id, football_player:footballPlayer}) => (
                            <option key={ id } value={ id }>{ footballPlayer.name }</option>
                          ))
                        }
                      </select>
                      {
                        scoreBoxParams.footballTeamOne.footballPlayers.length == index + 1 ?
                          <div className='mx-2' onClick={ () => handleAddMorePlayers(1) } 
                          dangerouslySetInnerHTML={{__html: icons.addCircleButton}}></div>
                          :
                          <div className='mx-2' onClick={ () => handleRemovePlayer(1, index) } 
                          dangerouslySetInnerHTML={{__html: icons.deleteCircleButton}}></div>
                          
                      }
                      <div dangerouslySetInnerHTML={{__html: icons.playerStatus}}></div>
                    </div>
                  </div>
                ))
              }
              
          </div>
          <div className='col-md-6'>
            <p className='section-title'>Equipo/Jugador 2</p>
            {
              scoreBoxParams.footballTeamTwo.footballPlayers.map((footballPlayer, index) => (
                <div className='mb-3'>
                  <label htmlFor="disabledSelect" className="form-label">Jugador {index + 1}</label>
                  <div className='d-flex'>
                    <select id="disabledSelect"
                      className="form-select"
                      value={ footballPlayer }
                      onChange={ ({target}) => handleSelectPlayerTwo(target.value, index) }
                    >
                      <option value=''>Elegir jugador</option>
                      {
                        footballPlayers.teamTwo.map(({id, football_player:footballPlayer}) => (
                          <option key={ id } value={ id }>{ footballPlayer.name }</option>
                        ))
                      }
                    </select>
                    {
                      scoreBoxParams.footballTeamTwo.footballPlayers.length == index + 1 ?
                        <div className='mx-2' onClick={ () => handleAddMorePlayers(2) } 
                        dangerouslySetInnerHTML={{__html: icons.addCircleButton}}></div>
                        :
                        <div className='mx-2' onClick={ () => handleRemovePlayer(2, index) } 
                        dangerouslySetInnerHTML={{__html: icons.deleteCircleButton}}></div>
                        
                    }
                    <div dangerouslySetInnerHTML={{__html: icons.playerStatus}}></div>
                  </div>
                </div>
              ))
            }
              
          </div>
        </div>
      </>
    :
    <></>
  )
}

FootballTemplate.propTypes = {}

export default FootballTemplate