import { useState } from "react";

const usePopup = () => {
    const [popUpState, setPopUpState] = useState(false);

    const showPopUp = () => {
        setPopUpState(true);
    }

    const closePopUp = () => {
        setPopUpState(false);
    }

    return {
        popUpState,
        showPopUp,
        closePopUp,
    }
}

export { usePopup }