import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SocialMediaCard = ({ icon, connection, closeIcon, disabled, update }) => {
  const [showModal, setShowModal] = useState(false);
  const {title, username, url} = connection;
  
  const [connected, setConnected] = useState([username, url].every(i => i));
  const [currentUsername, setCurrentUsername] = useState(username ?? ''); 
  const [currentUrl, setCurrentUrl] = useState(url ?? ''); 

  const handleConnection = () => {
    if ([currentUsername, currentUrl].every(i => i.trim())) {
      setConnected(true);
      setShowModal(false);
      return true
    }
    setShowModal(false);
    return false;
  };

  const handleDisconnect = () => {
    setConnected(false);
    setCurrentUsername('');
    setCurrentUrl('');
  }
  
  if (disabled) {
    return (
      <div className="social-media-card --locked">
        <img src={icon} alt={title} />
        <p>{title}</p>
        <span>No disponible en plan actual</span>
        <a href={update}>Actualizar plan</a>
      </div> 
    )
  }
  
  return (
    <div className="social-media-card">
      <img src={icon} alt={title} />
      <p>{title}</p>

      <input type="hidden" name={`connections[${title}][username]`} value={currentUsername} />
      <input type="hidden" name={`connections[${title}][url]`} value={currentUrl} />
      <input type="hidden" name={`connections[${title}][title]`} value={title} />

      {connected && 
        <span>{currentUsername}</span>
      }

      <button type="button" className={`social-media-card__connect ${connected ? '--disable' : ''}`} onClick={() => setShowModal(true)}>
        Conectar
      </button>

      {connected && 
        <button type="button" className="social-media-card__disconnect" onClick={handleDisconnect}>
          Desconectar
        </button>
      }

      {showModal && (
        <div className="social-media-card__overlay">
          <div className="social-media-card__form">
            <button type="button" onClick={() => setShowModal(false)} className="social-media-card__form__close">
              <img src={closeIcon} alt="Cerrar" />
            </button>

            <h2>Conectar con {title}</h2>

            <hr />

            <label className="form-label">
              Nombre de usuario
              <input
                type="text"
                placeholder="Agrega tu username"
                pattern="@"
                className="form-control"
                value={currentUsername}
                onChange={e => setCurrentUsername(e.target.value)}
              />
            </label>

            <label className="form-label">
              Link del perfil
              <input
                type="text"
                placeholder="Agrega el link de tu perfil"
                className="form-control"
                value={currentUrl}
                onChange={e => setCurrentUrl(e.target.value)}
              />
            </label>

            <hr />

            <div className="social-media-card__form__buttons">
              <button type="button" onClick={handleDisconnect}>Cancelar</button>
              <button type="button" onClick={handleConnection}>Conectar</button>
            </div>
          </div>
        </div>
      )}
  </div>
  )
};

export default SocialMediaCard;
