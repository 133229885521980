import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DaySchedule from './DaySchedule';

const TimeSchedule = ({ name, schedule, selected }) => {
  const parseDays = (obj) => {
    if (obj !== null) {
      const schedule = JSON.parse(obj.week);

      if (schedule !== null) {
        return schedule
      }
    };

    return ({
      sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [],
    });
  };

  const [week, setWeek] = useState(parseDays(schedule));
  const [showSchedule, setShowSchedule] = useState(selected === 'specific');
  const [scheduleType, setScheduleType] = useState(selected ?? 'unavailable');

  const handleSchedule = (value) => {
    if (value === 'specific') {
      setShowSchedule(true);
    } else {
      setShowSchedule(false);
    }

    setScheduleType(value);
  };

  const handleTimes = (name, day) => {
    setWeek({ ...week, [name]: day });
  };

  const RadioInput = ({ value }) => (
    <input
      type="radio"
      checked={scheduleType === value}
      onChange={() => handleSchedule(value)}
    />
  );

  return (
    <div className="schedule-selector">
      <h3>Horario Comercial</h3>
      <label>
        <RadioInput value="unavailable" />
        <p>
          <strong>Horario no disponible</strong>
          Las personas no verán el horario comercial en la página
        </p>
      </label>

      <label>
        <RadioInput value="open" />
        <p>
          <strong>Siempre abierto</strong>
          Ejm: Parques, calles
        </p>
      </label>

      <label>
        <RadioInput value="closed" />
        <p>
          <strong>Cerrado definitivamente</strong>
          Este negocio cerró definitivamente
        </p>
      </label>

      <label>
        <RadioInput value="variable" />
        <p>
          <strong>Horario variable</strong>
          Para negocios con horarios cambiantes
        </p>
      </label>

      <label>
        <RadioInput value="specific" />
        <p>
          <strong>Abierto en horario específico</strong>
          Especifica tu propio horario
        </p>
      </label>

      {showSchedule
        && (
          <>
            <DaySchedule day={week.monday} name="monday" updateSchedules={handleTimes} title="Lunes" />
            <DaySchedule day={week.tuesday} name="tuesday" updateSchedules={handleTimes} title="Martes" />
            <DaySchedule day={week.wednesday} name="wednesday" updateSchedules={handleTimes} title="Miercoles" />
            <DaySchedule day={week.thursday} name="thursday" updateSchedules={handleTimes} title="Jueves" />
            <DaySchedule day={week.friday} name="friday" updateSchedules={handleTimes} title="Viernes" />
            <DaySchedule day={week.saturday} name="saturday" updateSchedules={handleTimes} title="Sábado" />
            <DaySchedule day={week.sunday} name="sunday" updateSchedules={handleTimes} title="Domingo" />
          </>
        )}

      <input type="hidden" value={scheduleType} name={`${name}[schedule_type]`} />
      <input type="hidden" value={JSON.stringify(week)} name="schedule[week]" />
    </div>
  );
};

TimeSchedule.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  schedule: PropTypes.shape({
    sunday: PropTypes.arrayOf(PropTypes.object),
    monday: PropTypes.arrayOf(PropTypes.object),
    tuesdayday: PropTypes.arrayOf(PropTypes.object),
    wednesday: PropTypes.arrayOf(PropTypes.object),
    thursday: PropTypes.arrayOf(PropTypes.object),
    friday: PropTypes.arrayOf(PropTypes.object),
    saturday: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default TimeSchedule;
