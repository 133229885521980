import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import PlaylistForm from './PlaylistForm';
import { useUrlParams } from '../../hooks/useUrlParams';
import PlaylistPreview from './PlaylistPreview';
import { CreatePlaylistContext } from '../../contexts/CreatePlaylistContext';
import VideoMeilisearch from './VideoMeilisearch';
import PlaylistSendForm from './PlaylistSendForm';

const CreatePlaylist = React.memo(({urls, tags = [], authenticityToken, defaultData}) => {
  const [formData, setFormData] = useState({
    title: '',
    order: '',
    tags: [],
    videos: []
  });

  const formRef = useRef();

  const [playlistVideos, setPlaylistVideos] = useState([]);

  const handleSubmitForm = () => formRef.current.submit();

  const removeVideo = (id) => setPlaylistVideos(playlistVideos.filter(video => video.id != id));

  const { urlSearchData, selectOption, urlParams } = useUrlParams({
    tags: formData.tags,
    order: formData.order,
    pagination: '',
    search: ''
  });

  const addFormData = (name, value) => {
    if (formData.hasOwnProperty(name)) {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  }

  const tagFormat = (tagList) => tagList.map(tag => ({value: tag.id, label: tag.name}));

  useEffect(() => {
    selectOption({
      name: 'tags',
      value: formData.tags
    });
    selectOption({
      name: 'order',
      value: formData.order
    });
  }, [formData])

  useEffect(() => {
    setFormData(data => ({
      ...data,
      videos: playlistVideos.map(playlistVideo => playlistVideo.id)
    }));
  },[playlistVideos]);
  useEffect(() => {
    if (defaultData) {
      setPlaylistVideos(defaultData.videos);
      setFormData(data => ({
        ...data,
        title: defaultData.title,
        order: defaultData.order,
        tags: tagFormat(defaultData.tags)
      }));
    }
  }, [])

  return (
    <CreatePlaylistContext.Provider value={{ playlistVideos, setPlaylistVideos, urlParams, urls, formData }}>
      <div className='playlist-new'>
        <PlaylistPreview
          playlistVideos={ playlistVideos }
          removeVideo={ removeVideo }
          setPlaylistVideos={ setPlaylistVideos }
        />
        <PlaylistForm
          urlSearch={ urlSearchData }
          selectOption={ selectOption }
          addFormData={ addFormData }
          tags={ tags }
          defaultData={ defaultData }
          formData={ formData }
        />
      </div>
      <div className=''>
        <PlaylistSendForm
          isUpdate={ !!defaultData?.id }
          values={ formData }
          urls={ urls.formAction }
          authenticityToken={ authenticityToken }
          formRef={ formRef }
        />
        <div class="d-flex justify-content-between flex-wrap py-2 mt-5">
          <a href={ urls.goBack } class='btn btn-return'>Cancelar</a>
          <button
            class='btn btn-publish'
            onClick={handleSubmitForm}
          >Publicar</button>
        </div>
      </div>
    </CreatePlaylistContext.Provider>
  )
});

CreatePlaylist.propTypes = {}

export default CreatePlaylist
