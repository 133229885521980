import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CategorySubcategory = ({
  categories,
  subcategories,
  currentSubcategory,
  disabled,
  dbCategory,
  model = 'commerce',
  inputs  = {
    category: {
      name: 'category_id',
      label: 'Categoría'
    },
    subcategory: {
        name: 'subcategory_id',
        label: 'Giro'
    },
  }
}) => {
  const [subcategoryID, setSubcategoryID] = useState(currentSubcategory);
  const [currentCategory, setCurrentCategory] = useState(dbCategory);
  const [subcategoriesFiltered, setSubcategoriesFiltered] = useState([]);
  console.log(subcategoryID)

  const handleCategoryChange = (id) => {
    const category = categories.find((category) => category.id == id);

    if (category) {
      setCurrentCategory({ id, name: category.name });
    }
  };

  useEffect(() => {
    setSubcategoriesFiltered(subcategories.filter((sub) => sub[inputs.category.name] == currentCategory.id))
  }, [currentCategory])

  return (
    <div className="mb-3 row">
      <label className="col-6 form-sublabel">
        Categoría
        <select
          name={`${model}[${inputs.subcategory.name}]`}
          className="form-control"
          defaultValue={currentCategory ? currentCategory.id : 'default'}
          onChange={(e) => handleCategoryChange(e.target.value)}
          disabled={disabled}
        >
          <option value="default" disabled hidden>Seleccionar</option>

          {categories.map((item) => (
            <option value={item.id} key={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </label>

      <label className="col-6 form-sublabel">
        {!currentCategory && `${inputs.subcategory.label} (debes seleccionar una ${inputs.category.label.toLowerCase()})`}
        {currentCategory && `${inputs.subcategory.label} (mostrando ${inputs.subcategory.label.toLowerCase()}s de: ${currentCategory.name})`}

        <select
          className="form-control"
          name={`${model}[${inputs.subcategory.name}]`}
          disabled={!currentCategory || disabled}
          value={subcategoryID}
          onChange={(e) => setSubcategoryID(e.target.value)}
        >
          <option value="default" disabled hidden>Seleccionar</option>

          {subcategoriesFiltered.map((item) => (
            <option value={item.id} key={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

CategorySubcategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

CategorySubcategory.defaultProps = {
  currentSubcategory: 'default',
  disabled: false,
  dbCategory: false,
};

export default CategorySubcategory;
