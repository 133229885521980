import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PasswordChange = ({ name, disable }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="password-input">
      <input
        className="form-control"
        type={visible ? 'text' : 'password'}
        name={name}
        placeholder="*********"
        disabled={disable}
      />
      <button
        type="button"
        className={visible ? "open-eye" : "close-eye"}
        onClick={() => setVisible(!visible)}
      >
      </button>
    </div>
  );
};

PasswordChange.propTypes = {
  name: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default PasswordChange;
