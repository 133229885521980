import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SortableImage from './SortableImage';
import { ReactSortable } from "react-sortablejs";

const DropZone = ({ images, resource }) => {
  const fileInput = useRef();
  const [imageData, setImageData] = useState(images)

  const addFiles = everyFile => {
    const newFiles = everyFile.map(file => {
      const data = new DataTransfer();
      data.items.add(file);

      return {
        url:  URL.createObjectURL(data.files[0]),
        file: data.files
      }
    });

    setImageData([...imageData, ...newFiles])
  };
  
  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFileDrop = (e) => {
    handleDragOver(e)

    const { files } = e.dataTransfer;
    addFiles([...files])
  };
  
  const handleFileUpload = (e) => {
    const {files} = e.target;

    addFiles([...files]);
  };

  const handleRemoveImage = (e, index) => {
    e.stopPropagation();
    
    imageData.splice(index, 1);
    setImageData([...imageData]);
  };

  const handleSortDrop = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      className="dropzone mx-auto"
      onClick={() => fileInput.current.click()}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleFileDrop(e)}
    >

      {/* Dummy input for file uploads */}
      <input type="file" className="d-none" ref={fileInput} multiple onChange={(e) => handleFileUpload(e)} />

      {imageData.length <= 0 && <p className="fs-6">Arrastra aqui las imagenes o selecciona una</p>}

      {imageData.length > 0 && (
        <ReactSortable list={imageData} setList={setImageData} className="dropzone__area">
          {imageData.map((img, index) => 
            <div key={img.url} className="dropzone__image-wrapper" onDrop={(e) => handleSortDrop(e)}>
              <SortableImage name={`${resource}[]`} url={img.url} file={img.file} signedId={img.signedId} />
              <button className="dropzone__image-wrapper__delete" type="button" onClick={(e) => handleRemoveImage(e, index)} />
              <span className="dropzone__image-wrapper__move" />
            </div>)}
        </ReactSortable>
        )
      }

    </div>
  );
};

DropZone.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  resource: PropTypes.string.isRequired,
};

export default DropZone;
