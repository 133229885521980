import React, { useState, useRef } from "react";

const ListSort = ({ tableHead, tableData, sortPath, authenticityToken }) => {
  const {fields, content} = tableData;
  
  const [tableOrder, setTableOrder] = useState(content);
  const [showSave, setShowSave] = useState(false);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDrag = () => {
    const newOrder = [...tableOrder];

		//remove and save the dragged item content
		const draggedItemContent = newOrder.splice(dragItem.current, 1)[0];

		//switch positions
		newOrder.splice(dragOverItem.current, 0, draggedItemContent);

		//reset position refs
    cleanDragReferences();

		//update order
    setTableOrder(newOrder);

    // show save button
    setShowSave(true);
  };

  const handleSave = async () => {
    const params = new FormData();
    params.append('order_ids', JSON.stringify(tableOrder.map(i => i.id)));
    params.append('authenticity_token', authenticityToken);
    
    const response = await fetch(sortPath, {
      method: 'POST',
      body: params,
    })
    
    setShowSave(false);
  };

  const cleanDragReferences = () => {dragItem.current = null; dragOverItem.current = null};

  return (
    <div className="p-4 sort-table">
      { showSave && (
        <button
          className="btn btn-publish order-btn"
          onClick={handleSave}
        >
          Guardar Orden
        </button>
      )}
      <table className="table-general">
        <thead>
          <tr>
            {tableHead.map(title => (
              <th key={`table-head-${title}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableOrder.map((row, index) => (
            <tr
              key={`table-row-${index}`}
              draggable
              onDragStart={() => dragItem.current = index}
              onDragEnter={() => dragOverItem.current = index}
              onDragEnd={handleDrag}
            >
              {fields.map(field => (
                <td key={`table-data-${field}`}>
                  {row[field]}
                </td>
              ))}
              <td className="options">
                <a className="modify-icon" href={row.actions.edit}></a>
                <a className="delete-icon" data-method="delete" href={row.actions.delete}></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
};

export default ListSort;
