import React, { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import DateTimeScheduleItem from './DateTimeScheduleItem';

const DateTimeSchedule = ({railsModel, railsField, initSchedule, isEnable}) => {
    const [selectedDays, setSelectedDays] = useState([]);
    const [showDayPicker, setShowDayPicker] = useState(false)

    const [datetimeSchedules, setDatetimeSchedules] = useState([]);

    useEffect(() => {
        if (initSchedule.length > 0) {
            setDatetimeSchedules(
                initSchedule.map(initScheduleItem => {
                    setSelectedDays(selectedDay => [
                        ...selectedDay,
                        new Date(initScheduleItem.day)
                    ])

                    return ({
                    schedule_day: new Date(initScheduleItem.day),
                    schedule_type: parseInt(initScheduleItem.schedule_type),
                    schedule_time: {
                        opening: initScheduleItem.opening,
                        closing: initScheduleItem.closing,
                    }});
                })
            );
        }
    }, [])


    const handleDayClick = (day, { selected }) => {
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay => 
                DateUtils.isSameDay(selectedDay, day)
            );
            setDatetimeSchedules((datetimeSchedules) => (
                datetimeSchedules.filter((item, index) => selectedIndex !== index)
            ))
            setSelectedDays(selectedDays => selectedDays.filter(selectedDay => selectedDay !== selectedDays[selectedIndex]));
        } else {
            setSelectedDays(selectedDays => [...selectedDays, day]);
            setDatetimeSchedules(items => [...items, {
                schedule_day: day,
                schedule_type: 2,
                schedule_time: {
                    opening: '',
                    closing: '',
                },
            }]);
        }
    }

    const handleShowDayPicker = (e) => {
        e.preventDefault();
        setShowDayPicker(!showDayPicker);
    }

    const editDateTimeSchedule = (index, eventScheduleEdited) => {
        setDatetimeSchedules(datetimeSchedules => (
            datetimeSchedules.map((eventsSchedule, eventsScheduleIndex) => {
                if (index == eventsScheduleIndex) {
                    return {
                        ...eventsSchedule,
                        ...eventScheduleEdited
                    }
                }
                return eventsSchedule;
            })
        ));
    }

    const removeDateTimeSchedule = (day) => {
        setSelectedDays(selectedDays.filter(x => x != day));
        setDatetimeSchedules(datetimeSchedules.filter(x => x.schedule_day != day));
    }

    const CalendarIcon = (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.75 2H14.75C14.9489 2 15.1397 2.07902 15.2803 2.21967C15.421 2.36032 15.5 2.55109 15.5 2.75V14.75C15.5 14.9489 15.421 15.1397 15.2803 15.2803C15.1397 15.421 14.9489 15.5 14.75 15.5H1.25C1.05109 15.5 0.860322 15.421 0.71967 15.2803C0.579018 15.1397 0.5 14.9489 0.5 14.75V2.75C0.5 2.55109 0.579018 2.36032 0.71967 2.21967C0.860322 2.07902 1.05109 2 1.25 2H4.25V0.5H5.75V2H10.25V0.5H11.75V2ZM10.25 3.5H5.75V5H4.25V3.5H2V6.5H14V3.5H11.75V5H10.25V3.5ZM14 8H2V14H14V8Z" fill="#6E7191"/>
            </svg>
        </>
    )

    return (
        <div className="form-group">
            <div className="position-relative">
                <div
                    className="input-group mb-3"
                    onClick={(e) => {!isEnable && handleShowDayPicker(e)}}
                >
                    <span className="input-group-text" id="inputGroup-sizing-default">{CalendarIcon}</span>
                    <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        placeholder="Selecciona las fechas"
                        disabled={isEnable}
                    />
                </div>
                {
                    showDayPicker &&
                        <div className="day-picker-custom">
                            <DayPicker
                                selectedDays={selectedDays}
                                onDayClick={handleDayClick}
                                className="day-picker"
                            />
                        </div>
                }
                {
                    selectedDays.length > 0 &&
                        <table className="table-general table-event-schedule">
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Tipo</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datetimeSchedules.sort((a,b) => a.schedule_day - b.schedule_day).map((selectedDay, index) => (
                                        <DateTimeScheduleItem
                                            key={ index}
                                            selectedDay={ selectedDay } 
                                            editDateTimeSchedule={ editDateTimeSchedule }
                                            index={index}
                                            onRemoveDay={ removeDateTimeSchedule }
                                        />))
                                }
                            </tbody>
                        </table>
                }                
            </div>
            <input
                type="hidden"
                name={`${railsModel}[${railsField}]`}
                value={ JSON.stringify(datetimeSchedules) }
            />
        </div>
    );
}

export default DateTimeSchedule;
