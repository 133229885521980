import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SpecificFilterItem from './SpecificFilterItem';

const PopUpNewSpecificFilter = ({ url, authenticityToken, railsFilter, railsSubFilters }) => {
    const [showPopUp, setShowPopUp] = useState(false);

    const handleClosePopUp = (e) => {
        e.preventDefault();
        setShowPopUp(false);
    }

    const handleOpenPopUp = (e) => {
        e.preventDefault();
        setShowPopUp(true);
    }

    const [subFilter, setSubFilter] = useState('');
    const [subFiltersList, setSubFiltersList] = useState([]);



    const handleAddSubFilter = (e) => {
        e.preventDefault();
        if (!(!subFilter || /^\s*$/.test(subFilter)) && !subFiltersList.includes(subFilter)) {
            setSubFiltersList(item => [...item, subFilter.trim()]);
            setSubFilter('');
        }
    }

    const removeSubFilter = (name) => {
        const newSubFilter = subFiltersList.filter(subFilterItem => subFilterItem !== name);
        setSubFiltersList(newSubFilter);
    }

    useEffect(() => {
        if (railsSubFilters) {
            setSubFiltersList(railsSubFilters.map(items => items.name));
        }
    }, []);

    return (
        <>
        {
            railsFilter ?
                <a
                    className="modify-icon"
                    href="#"
                    onClick={ handleOpenPopUp }>
                </a>
                :
                <a
                    href="#"
                    onClick={ handleOpenPopUp }
                >
                    <svg className="mx-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6V0H8V6H14V8H8V14H6V8H0V6H6Z" fill="#3B5284"/>
                    </svg>
                    Añadir nuevo
                </a>
        }
        {
            showPopUp &&
                <div className="popup-container">
                    <div className="popup --confirm-commerce">
                        <div className="popup__header">
                            <div>Nuevo filtro especifico</div>
                            <div className="popup__header__close">
                                <button
                                    className="popup__header__close__button --red-button"
                                    onClick={ () => setShowPopUp(false) }
                                >
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z" fill="#021D49"/>
                                    </svg>
                                </button>
                            </div> 
                        </div>
                        <form method="post"  autoComplete="off" action={ url } >
                            <div className="popup__body text-start p-3 py-4">
                                <input
                                    type="hidden"
                                    name="authenticity_token"
                                    value={ authenticityToken }                
                                />
                                {
                                    railsFilter &&
                                        <input type="hidden" name="_method" value="PUT" />
                                }

                                <div className="mb-3">
                                    <label className="form-label">Nombre del filtro</label>
                                    <input
                                        type="text"
                                        name="filter[name]"
                                        className="form-control"
                                        placeholder="Ejemplo: Reservar por"
                                        defaultValue={ railsFilter ? railsFilter.name : ''}
                                    ></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Lista de subfiltros</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ejemplo: Whatsapp"
                                            value={ subFilter }
                                            onChange={ ({ target }) => { setSubFilter(target.value) } }
                                        />
                                        <input
                                            type="hidden"
                                            value={ JSON.stringify(subFiltersList) }
                                            name="filter[subfilters]"
                                        />
                                        <button
                                            className="btn btn-danger text-white"
                                            type="button"
                                            id="button-addon2"
                                            onClick={ handleAddSubFilter }
                                        >Añadir</button>
                                    </div>
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        subFiltersList.map(subFiltersList => <SpecificFilterItem removeSubFilter={ removeSubFilter } key={subFiltersList} name={subFiltersList} />)
                                    }
                                    
                                </ul>
                            </div>
                            <div className="popup__footer justify-content-center flex-row-reverse">
                                <button type="submit" className="btn btn-publish mx-3">{ railsFilter ? 'Actualizar' : 'Crear' }</button>
                                <button
                                    className="btn btn-return"
                                    onClick={ handleClosePopUp }
                                >Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
        }
        </>
    );
}

PopUpNewSpecificFilter.propTypes = {

}

export default PopUpNewSpecificFilter;
