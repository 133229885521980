import React, { useState } from "react";
import PopUp from "../layout/PopUp";

const TagAppend = ({ url, token }) => {
  const [showPopUp, setShowPopUp] = useState();
  const [query, setQuery] = useState("");

  const handleOpenPopUp = () => {
    setShowPopUp(true);
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <>
      <a className="add-icon" onClick={handleOpenPopUp} />
      {showPopUp && (
        <PopUp popUpClass="--confirm-commerce" closeAction={handleClosePopUp}>
          <div className="popup__header border-0">
            <h3>Etiquetar artículos</h3>
            <div className="popup__header__close">
              <button
                className="popup__header__close__button --red-button"
                onClick={handleClosePopUp}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z"
                    fill="#021D49"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="popup__body">
            <input
              value={query}
              onChange={({ target }) => setQuery(target.value)}
              className="form-control"
              placeholder="Buscar en artículos"
            ></input>
          </div>
          <div className="popup__footer justify-content-center">
            <button className="btn btn-return" onClick={handleClosePopUp}>
              Cancelar
            </button>
            <form method="post" action={url} className="d-inline-block">
              <input value={query} type="hidden" name="query" />
              <input value={token} type="hidden" name="authenticity_token" />
              <button className="btn btn-publish mx-2 w-auto h-auto text-danger d-inline-block">
                Buscar
              </button>
            </form>
          </div>
        </PopUp>
      )}
    </>
  );
};

export default TagAppend;
