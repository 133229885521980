import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CreateArticleContext } from '../../contexts/CreateArticleContext';
import FootballTemplate from './FootballTemplate';
import { useScoreBoxParams } from '../../hooks/useScoreBoxParams';

const ScoreBoxForm = ({ showScoreBox, editScoreBox }) => {
  // States
  const [showScoreBoxContent, setShowScoreBoxContent] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { pathNames, icons } = useContext(CreateArticleContext);

  const {
    selectFootballTeamOne,
    selectFootballTeamTwo,
    selectFootballLeague,
    footballLeagues,
    footballTeams,
    selectPlayer,
    scoreBoxParams,
    footballPlayers,
    addPlayerToTeam,
    removePlayerToTeam
  } = useScoreBoxParams(pathNames)

  useEffect(() => {
    editScoreBox(scoreBoxParams)
  }, [scoreBoxParams])
  

  const handleSelectFootballLeague = ({ target }) => {
    selectFootballLeague(target.value);
  }

  const handleSelectFootballTeamOne = ({ target }) => {
    selectFootballTeamOne(target.value);
  }

  const handleSelectFootballTeamTwo = ({ target }) => {
    selectFootballTeamTwo(target.value);
  }
  
  const handleDropScoreBox = () => {
    setShowScoreBoxContent(true)
  }

  return (
    <>
      {
      showScoreBox &&
        <div
          className='score-box__drop-area'
          onDragOver={(e) => {e.preventDefault()}}
          onDrop={handleDropScoreBox}
        >
          <p>
            Agregar score box
          </p>
        </div>
      }
      {
      showScoreBoxContent &&
        <div
          className='score-box__body'
        >
          <p
            className='h5 score-box__title'
            onClick={ () => {setShowForm(!showForm) }}
          >SCORE-BOX (Fútbol)</p>
          <div className='content'>
            <div className='content__header'>
              <button className='btn btn-return'>Volver a empezar</button>
              <select
                className='form-select'
                onChange={ handleSelectFootballLeague }
                value={ scoreBoxParams.footballLeagueId}
              >
                <option value=''>Elegir Liga</option>
                {
                  footballLeagues.map(({id, name}) => (
                    <option key={ id } value={ id }>{ name }</option>
                  ))
                }
              </select>
            </div>
            {
              showForm &&
                <div className='content__body'>
                  <p className='section-title'>Datos de equipo/jugadores</p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor="disabledSelect" className="form-label">Equipo/Jugador 1</label>
                      <select
                        onChange={ handleSelectFootballTeamOne }
                        className="form-select"
                        value={ scoreBoxParams.footballTeamOne.footballTeam }
                      >
                        <option value=''>Elegir equipo o jugador</option>
                        {
                          footballTeams.map(({id, name}) => (
                            <option key={ id } value={ id }>{ name }</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor="disabledSelect" className="form-label">Equipo/Jugador 2</label>
                      <select
                        onChange={ handleSelectFootballTeamTwo }
                        className="form-select"
                        value={ scoreBoxParams.footballTeamTwo.footballTeam }
                      >
                        <option value=''>Elegir equipo o jugador</option>
                        {
                          footballTeams.map(({id, name}) => (
                            <option key={ id } value={ id }>{ name }</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  
                  <p className='section-title mt-4'>Resultados de equipo</p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor="disabledSelect" className="form-label">Equipo/Jugador 1</label>
                      <input type="text" className="form-control" placeholder='Ingresar goles' />
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor="disabledSelect" className="form-label">Equipo/Jugador 2</label>
                      <input type="text" className="form-control" placeholder='Ingresar goles' />
                    </div>
                  </div>
                  {
                    <FootballTemplate
                      currentPlayersTeamOne={ scoreBoxParams.footballTeamOne.footballPlayers }
                      currentPlayersTeamTwo={ scoreBoxParams.footballTeamTwo.footballPlayers }
                      footballPlayers={ footballPlayers }
                      scoreBoxParams={ scoreBoxParams }
                      addPlayerToTeam={ addPlayerToTeam }
                      removePlayerToTeam={ removePlayerToTeam }
                      selectPlayer={ selectPlayer }
                    />
                  }
                  <p className='section-title my-4'>Datos adicionales</p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div>
                        <label htmlFor="disabledSelect" className="form-label">Arbitro del partido</label>
                        <input type="text" className="form-control" placeholder='Ingresar arbitro' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor="disabledSelect" className="form-label">Estadio</label>
                      <input type="textarea" className="form-control" placeholder='Ingresar estadio' />
                    </div>
                  </div>
                  <div className='mt-4'>
                    <label htmlFor="disabledSelect" className="form-label">Incidentes o notas adicionales</label>
                    <textarea
                      className="form-control"
                      placeholder='Ejemplo: 25.000 espectadores'
                    ></textarea>
                  </div>
                  <div className='d-flex flex-row-reverse mt-3'>
                    <button className='btn btn-publish'>Guardar</button>
                  </div>
                </div>
            }
          </div>
        </div>
      }
    </>
    
  )
}

ScoreBoxForm.propTypes = {}

export default ScoreBoxForm;