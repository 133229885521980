import React, { useContext, useState } from "react"
import MeiliSearch from "meilisearch";
import { CreatePlaylistContext } from "../../contexts/CreatePlaylistContext";
import { useEffect } from "react";


const VideoMeilisearch = React.memo(({ setVideos }) => {
  const [results, setResults] = useState(null);
  const [searchText, setSearchText] = useState('');

  const { formData } = useContext(CreatePlaylistContext);
  const { tags, videos } = formData;


  const formatTags = () => {
    const formatElements = tags.map(tag => `tags = ${tag.label}`);
    const result = formatElements.join(' OR ');

    return result;
  }

  const handleChangeSearchText = ({ target }) => {
    setSearchText(target.value);
  }

  const client = new MeiliSearch({
    host: 'https://meili.grada.mx',
    apiKey: 'z3jhzge6igvsihblcmlvzgljbybkzxbv',
  });

  const searchMeili = async () => {
    const results = await client.index('OttVideo').search(searchText, {
      filter: [
        formatTags()
      ]
    });

    return results;
  }


  useEffect(async () => {
    const getResults = await searchMeili();
    setResults(getResults);
  }, [searchText])

  useEffect(async () => {
    if (results) {
      setVideos(results.hits)
    }
  }, [results])

  return (
    <>
      <input
        type="text"
        className='form-control'
        onChange={ handleChangeSearchText }
        value={ searchText }
        placeholder='Buscar por titulo, tag o deporte'
      />
    </>
  )
});

export default VideoMeilisearch;
