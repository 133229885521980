import React from 'react';
import PropTypes from 'prop-types';
import PopUpConfirm from '../components/PopUpConfirm';
import { usePopup } from '../hooks/usePopup';

const EventsTr = ({ event, removeElement }) => {
    const { popUpState, showPopUp, closePopUp,} = usePopup();

    const handleRemoveElement = (e) => {
        e.preventDefault();
        showPopUp();
    }

    const removeElementWithId = () => {
        removeElement(event.id)
    }

    return (
        <tr>
            <td>
                <div className="thumb">
                    <img src={event.coverPage} alt="" srcset={event.coverPage} />
                </div>
            </td>
            <td>
                <p className="td-title">{ event.title }</p>
                <p className="td-body">{ event.place }</p>
            </td>
            <td>
                <span className="tag-badge">{ event.category }</span>
            </td>
            <td>$20.00 MXN - $30.00 MXN</td>
            <td>Mar 18 MAY - Sáb 22 MAY</td>
            <td>
                <div className="options">
                    <a
                        href="#"
                        className="delete-icon"
                        onClick={ handleRemoveElement }
                    ><i className="fas fa-times"></i></a>
                </div>
                {
                    popUpState && 
                        <PopUpConfirm
                            text="¿Estás seguro que deseas eliminar el evento?"
                            action={ removeElementWithId }
                            closePopUp={ closePopUp }
                        />
                }
            </td>
        </tr>
    );
}

EventsTr.propTypes = {
    event: PropTypes.object.isRequired,
    removeElement: PropTypes.func.isRequired
}

export default EventsTr;