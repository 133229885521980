import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const ProfilePicture = ({ picture, defaultPicture }) => {
  const [imageURL, setImageURL] = useState(picture);
  const fileInput = useRef();

  const handlePictureSelect = () => { 
    fileInput.current.click();
  };

  const handlePictureChange = () => {
    const file = fileInput.current.files[0];
    setImageURL(URL.createObjectURL(file));
  };
  
  return (
    <label className="profile-picture">
      <img src={imageURL ?? defaultPicture} alt="Foto de perfil" />

      <button type="button" onClick={handlePictureSelect} />

      <input
        type="file"
        name="user[profile_picture]"
        className="d-none"
        ref={fileInput}
        onChange={handlePictureChange}
      />
    </label>
  )
};

ProfilePicture.propTypes = {
  picture: PropTypes.string,
  defaultPicture: PropTypes.string.isRequired,
};

export default ProfilePicture;