import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DiscountFields = ({ discount, hasDiscount, resource }) => {
  const [amount, setAmount] = useState(discount);
  // const [useDiscount, setUseDiscount] = useState(hasDiscount);
  const [useDiscount, setUseDiscount] = useState(true);

  const handleDiscount = (e) => {
    const needDiscount = e.target.checked;

    setAmount(needDiscount ? amount : 0);
    // setUseDiscount(needDiscount);
    setUseDiscount(true);
  };

  return (
    <>
      <label className="form-label" htmlFor={`${resource}_discount`}>Aplicar descuento</label>

      <label className="switch --switch-dark mx-3 d-none">
        <input
          type="checkbox"
          name={`${resource}[has_discount]`}
          value={useDiscount}
          checked={useDiscount}
          onChange={(e) => handleDiscount(e)}
        />
        <span className="slider" />
      </label>

      <div className="input-group">
        <input
          type="number"
          min={0}
          max={100}
          step={5}
          placeholder="0"
          id={`${resource}_discount`}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="form-control px-3"
          name={`${resource}[discount]`}
          disabled={!useDiscount}
        />

        <span className="input-group-text">%</span>
      </div>
    </>
  );
};

DiscountFields.propTypes = {
  discount: PropTypes.number,
  hasDiscount: PropTypes.bool,
  resource: PropTypes.string.isRequired,
};

DiscountFields.defaultProps = {
  discount: 0,
  hasDiscount: false,
};

export default DiscountFields;
