const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Augosto',
    'Septiembre',
    'Octuber',
    'Noviember',
    'Diciember'
];

const shortMonths = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
];

const days = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo',
];

const shortDays = [
    'Dom',
    'Lun',
    'Mar',
    'Mie',
    'Jue',
    'Vie',
    'Sab',
];

const getShortFullDate = (initDate) => {
    const originalDate = new Date(initDate);
    const dayIndex = originalDate.getDay();
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();

    return `${shortDays[dayIndex]} ${day} ${shortMonths[monthIndex]}`;
};

const getShortDate = (initDate) => {
    const originalDate = new Date(initDate)

    return `${shortDays[originalDate.getDay()]} ${originalDate.getDate()}/${originalDate.getMonth() +1}`
};

export {getShortFullDate, getShortDate}