import React from 'react';

const EventsTrPopUp = ({event, addElement, removeElement, isChecked}) => {
    const handleChangeElement = (e) => {
        if (e.target.checked) {
            addElement(event);
        } else {
            removeElement(event.id);
        }
    }

    return (
        <tr>
            <td>
                <div className="thumb">
                    <img src={event.coverPage} alt="" srcset={event.coverPage} />
                </div>
            </td>
            <td>
                <p className="td-title">{ event.title }</p>
                <p className="td-body">{ event.place }</p>
            </td>
            <td>
                <span className="tag-badge">{ event.category }</span>
            </td>
            <td>$20.00 MXN - $30.00 MXN</td>
            <td>Mar 18 MAY - Sáb 22 MAY</td>
            <td>
                <label class="switch">
                    <input
                        type="checkbox"
                        id="set_popular"
                        onChange={ handleChangeElement }
                        checked={ isChecked }
                    />
                    <span class="slider"></span>
                </label>
            </td>
        </tr>
    );
} 

export default EventsTrPopUp;
