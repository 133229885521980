import React, { useState } from "react";
import { useEffect } from "react";

const SelectAuthor = ({ onSelect, user }) => {
  const [authors, setAuthors] = useState([]);

  const getAuthors = async () => {
    try {
      const response = await fetch(`/filters/users/get_authors`);
      const users = await response.json();

      setAuthors(users);
    } catch (error) {
      throw new Error(`Something failed on get users`);
    }
  };

  useEffect(() => {
    getAuthors();
  }, []);

  return (
    <select
      defaultValue="select"
      className="form-control mt-2"
      value={user}
      onChange={(e) => onSelect("user_id", e.target.value)}
    >
      <option value="select">Seleccionar autor</option>
      {authors.map((author) => (
        <option value={author.id} key={author.name}>
          {author.name}
        </option>
      ))}
    </select>
  );
};

export default SelectAuthor;
