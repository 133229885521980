import { useState, useEffect } from "react";

const useScoreBoxParams = (pathNames) => {
  const [footballTeams, setFootballTeams] = useState([]);
  const [footballLeagues, setFootballLeagues] = useState([]);
  const [footballPlayers, setFootballPlayers] = useState({
    teamOne: [],
    teamTwo: []
  })
  const [scoreBoxParams, setScoreBoxParams] = useState({
    footballLeagueId: '',
    stadium: 'Santiago Bernabeu',
    referee: 'Pierluigi Collina',
    footballTeamOne: {
      result: 5,
      footballTeam: '',
      footballPlayers: ['']
    },
    footballTeamTwo: {
      result: 2,
      footballTeam: '',
      footballPlayers: ['']
    }
  });

  const getFootballLeagues = async () => {
    try {
        const response = await fetch(pathNames.footballLeaguesPath);
        const subcategoriesResponse = await response.json();
        setFootballLeagues(subcategoriesResponse);
    } catch (error) {
        throw new Error(`Something failed on get football leagues`);
    }
  }

  const getFootballTeams = async (footballLeagueId) => {
    try {
      const response = await fetch(`${pathNames.footballTeamsPath}?league=${ footballLeagueId }`);
      const footballTeamsResponse = await response.json();
      setFootballTeams(footballTeamsResponse);
    } catch (error) {
        throw new Error(`Something failed on get football leagues`);
    }
  }

  const getFootballPlayers = async (footballTeamId, number) => {
    try {
      const response = await fetch(`${pathNames.footballPlayersPath}?team=${ footballTeamId }`);
      const footballPlayersResponse = await response.json();

      if (number == 1) {
        setFootballPlayers(footballPlayer => ({...footballPlayer, teamOne: footballPlayersResponse}));
      } else if (number == 2) {
        setFootballPlayers(footballPlayer => ({...footballPlayer, teamTwo: footballPlayersResponse}));
      }
    } catch (error) {
        throw new Error(`Something failed on get football players`);
    }
  }

  const selectFootballLeague = ( value ) => {
    setScoreBoxParams(scoreBox => ({
      ...scoreBox,
      footballLeagueId: value
    }))
  }

  const selectFootballTeamOne = ( value ) => {
    setScoreBoxParams(scoreBox => ({
      ...scoreBox,
      footballTeamOne: {
        ...scoreBox.footballTeamOne,
        footballTeam: value,
      }
    }));
  }

  const selectPlayer = ( teamNumber, value, index  ) => {
    if (teamNumber === 1) {
      const newfootballPlayers = scoreBoxParams.footballTeamOne.footballPlayers.map((itemValue, itemIndex) => (
        itemIndex == index ? value : itemValue
      ))
  
      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamOne: {
          ...scoreBox.footballTeamOne,
          footballPlayers: [
            ...newfootballPlayers
          ],
        }
      }));
    } else if (teamNumber === 2) {
      const newfootballPlayers = scoreBoxParams.footballTeamTwo.footballPlayers.map((itemValue, itemIndex) => (
        itemIndex == index ? value : itemValue
      ))
  
      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamTwo: {
          ...scoreBox.footballTeamTwo,
          footballPlayers: [
            ...newfootballPlayers
          ],
        }
      }));
    }
  }

  const addPlayerToTeam = (number) => {
    if (number === 1) {
      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamOne: {
          ...scoreBox.footballTeamOne,
          footballPlayers: [
            ...scoreBox.footballTeamOne.footballPlayers,
            ''
          ],
        }
      }));
    } else if (number === 2) {
      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamTwo: {
          ...scoreBox.footballTeamTwo,
          footballPlayers: [
            ...scoreBox.footballTeamTwo.footballPlayers,
            ''
          ],
        }
      }));
    }
  }

  const removePlayerToTeam = (teamNumber, index) => {
    if (teamNumber === 1) {
      const newfootballPlayers = scoreBoxParams.footballTeamOne.footballPlayers.filter((itemValue, itemIndex) => (
        itemIndex != index 
      ))

      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamOne: {
          ...scoreBox.footballTeamOne,
          footballPlayers: [
            ...newfootballPlayers
          ],
        }
      }));
    } else if (teamNumber === 2) {
      const newfootballPlayers = scoreBoxParams.footballTeamTwo.footballPlayers.filter((itemValue, itemIndex) => (
        itemIndex != index 
      ));

      setScoreBoxParams(scoreBox => ({
        ...scoreBox,
        footballTeamTwo: {
          ...scoreBox.footballTeamTwo,
          footballPlayers: [
            ...newfootballPlayers
          ],
        }
      }));
    }
  }

  const selectPlayerTeamTwo = ( value ) => {
    setScoreBoxParams(scoreBox => ({
      ...scoreBox,
      footballTeamTwo: {
        ...scoreBox.footballTeamTwo,
        footballPlayers: [
          ...scoreBox.footballTeamTwo.footballPlayers,
          value
        ],
      }
    }));
  }

  const selectFootballTeamTwo = ( value ) => {
    setScoreBoxParams(scoreBox => ({
      ...scoreBox,
      footballTeamTwo: {
        ...scoreBox.footballTeamTwo,
        footballTeam: value,
      }
    }));
  }

  useEffect(() => {
    if (scoreBoxParams.footballLeagueId) {
      getFootballTeams(scoreBoxParams.footballLeagueId)
    }
  }, [scoreBoxParams.footballLeagueId]);

  useEffect(() => {
    getFootballLeagues()
  }, []);
  
  useEffect(() => {
    if (scoreBoxParams.footballTeamOne.footballTeam) {
      getFootballPlayers(scoreBoxParams.footballTeamOne.footballTeam, 1)
    }
  }, [ scoreBoxParams.footballTeamOne.footballTeam ]);
  
  useEffect(() => {
    if (scoreBoxParams.footballTeamTwo.footballTeam) {
      getFootballPlayers(scoreBoxParams.footballTeamTwo.footballTeam, 2)
    }
  }, [ scoreBoxParams.footballTeamTwo.footballTeam ]);

  return {
    selectFootballTeamOne,
    selectFootballTeamTwo,
    selectFootballLeague,
    selectPlayer,
    footballLeagues,
    footballTeams,
    footballPlayers,
    addPlayerToTeam,
    removePlayerToTeam,
    scoreBoxParams
  }
}

export { useScoreBoxParams }