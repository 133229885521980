import React, { useState } from 'react';
import DropZone from '../components/DropZone';

const Slideshow = () => {
  const [currentImages, setCurrentImages] = useState([]);
  const [imageFormat, setImageFormat] = useState('file');
  const [showFormat, setShowFormat] = useState(false);

  const [imageUrl, setImageUrl] = useState('');
  const [imageTitle, setImageTitle] = useState('');
  const [fileName, setFileName] = useState('Ningún archivo seleccionado');

  const handleImageFormat = (e) => {
    setImageFormat(e.target.value);
  };

  const emptyFields = () => {
    setImageUrl('');
    setImageTitle('');
    setFileName('Ningún archivo seleccionado');
  };

  const getImageInfo = (files) => {
    setFileName(files[0].name);
    setImageUrl(URL.createObjectURL(files[0]));
  };

  const handleImageUpload = () => {
    if (imageFormat === 'url') {
      // Logic to upload url images to database
    } else if (imageFormat === 'file') {
      // Logic to upload files to S3
    }

    setCurrentImages([...currentImages, imageUrl]);
    emptyFields();
  };

  return (
    <div className="event-slideshow">
      <DropZone
        images={currentImages}
        clickEvent={() => setShowFormat(true)}
        updateImages={setCurrentImages}
      />

      <div className={showFormat ? 'event-slideshow__overlay' : 'd-none'}>
        <div className="event-slideshow__format-picker">
          <h2>Subir imágen</h2>
          <button
            type="button"
            className="event-slideshow__format-picker__close"
            onClick={() => setShowFormat(false)}
          >
            x
          </button>
          <label>
            Título
            <input placeholder="Ingresa el título de la imágen" value={imageTitle} onChange={(e) => setImageTitle(e.target.value)} />
          </label>

          <div className="event-slideshow__format-picker__radio">
            <label>
              <input
                type="radio"
                name="imageType"
                value="file"
                defaultChecked
                onClick={(e) => handleImageFormat(e)}
              />
              Subir imágen
            </label>

            <label>
              <input
                type="radio"
                name="imageType"
                value="url"
                onClick={(e) => handleImageFormat(e)}
              />
              Desde URL
            </label>
          </div>

          {imageFormat == 'file'
            && (
            <div className="event-slideshow__format-picker__file-select">
              <label>
                <span>Seleccionar archivos</span>
                <p>{fileName}</p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => getImageInfo(e.target.files)}
                />
              </label>
              <p>Dimensiones de la imágen 1800x860 px</p>
            </div>
            )}

          {imageFormat == 'url'
            && (
            <input
              placeholder="Inserte URL de la imágen"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
            />
            )}

          <div className="event-slideshow__format-picker__buttons">
            <button type="button" className="btn mx-2" onClick={emptyFields}>Cancelar</button>
            <button
              type="button"
              className="btn mx-2"
              onClick={handleImageUpload}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
