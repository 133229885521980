import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const Synonyms = ({ name, list, icons, token, path }) => {
  const [showModal, setShowModal] = useState(false);
  const [synonyms, setSynonyms] = useState([...list]);
  const [remove, setRemove] = useState([]);
  const [input, setInput] = useState('');

  const handleAddSynonym = () => {
    if (input.trim().length === 0) return
    
    setSynonyms([...synonyms, input]);
    setInput('');
  };

  const handleRemove = term => {
    setRemove([...remove, term]);
    setSynonyms(synonyms.filter(s => s != term));
  };
  
  return (
    <>
      <button
        type="button"
        className="btn bg-pink text-danger px-3 mx-3"
        onClick={() => setShowModal(true)}
      >
        <strong>
          <span dangerouslySetInnerHTML={{__html: icons.button}} />
          Sinónimos
        </strong>
      </button>

      {showModal && (
        <div className="popup-container">
          <div className="popup --confirm-commerce">
            <div className="popup__header">
              <div>Agregar sinónimos</div>
              <div className="popup__header__close">
                <button
                  className="popup__header__close__button --red-button"
                  onClick={ () => setShowModal(false) }
                >
                  <span dangerouslySetInnerHTML={{__html: icons.close}} />
                </button>
              </div>
            </div>
            <form method="post" autoComplete="off" action={ path } >
              <div className="popup__body text-start p-3 py-4">
                <input
                    type="hidden"
                    name="authenticity_token"
                    value={ token }                
                />

                <div className="mb-3">
                  <label className="form-label">Sinónimos de {name}</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={name}
                      value={input}
                      onChange={({ target }) => setInput(target.value)}
                    />
                    <input
                      type="hidden"
                      value={ JSON.stringify(synonyms) }
                      name="synonyms"
                    />
                    {
                      remove.length > 0 && (
                        <input
                          type="hidden"
                          value={ JSON.stringify(remove) }
                          name="remove"
                        />
                      )
                    }
                    <button
                      className="btn btn-danger text-white"
                      type="button"
                      onClick={ handleAddSynonym }
                    >
                      Añadir
                    </button>
                  </div>
                </div>
                <ul className="list-group list-group-flush --no-overflow">
                  {synonyms.map(s => (
                    <li className="list-group-item d-flex justify-content-between" key={s}>
                      {s}
                      <button type="button" onClick={() => handleRemove(s)} className="popup__header__close__button --red-button">
                        <span dangerouslySetInnerHTML={{__html: icons.close}} />
                      </button>
                    </li>  
                  ))}
                </ul> 
              </div>
              <div className="popup__footer justify-content-center flex-row-reverse">
                <button type="submit" className="btn btn-publish mx-3">Guardar</button>
                <button
                  className="btn btn-return"
                  onClick={() => setShowModal(false) }
                >Cancelar</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
};

Synonyms.propTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
  token: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default Synonyms;
