import React, {useState} from 'react';
import PropTypes from 'prop-types';

const MenuServiceCategory = ({ allCategories, currentCategory }) => {
  const [addCategories, setAddCategories] = useState(false);
  const [newCategories, setNewCategories] = useState(allCategories.map(c => c.name));
  const [hideForm, setHideForm] = useState(false);

  const handleAddCategories = (e) => {
    if (e.target.value === 'add new') {
      setAddCategories(true);
      setHideForm(false);
    };

    return;
  }

  const handleCategoryChange = (e, i) => {
    newCategories[i] = e.target.value;
    setNewCategories([...newCategories])
  }

  const handleAddQueue = () => {
    if (newCategories.every(c => c.trim() !== '')) {
      setHideForm(true);
    }
  }

  const handleRemoveQueue = () => {
    setAddCategories(false);
    setNewCategories(allCategories.map(c => c.name));
  };

  return (
    <>
      <label className="form-label" htmlFor="category_id">
        Categoría
      </label>

      <select
        name="categories[number]"
        className="form-control"
        defaultValue={currentCategory ? currentCategory.number : 'default'}
        id="category_id"
        onClick={(e) => handleAddCategories(e)}
      >
        <option value="default" disabled hidden>Seleccionar</option>          
        {newCategories.map((ctgy, i) => (<option value={i} key={ctgy}>{ctgy}</option>))}          

        <option value="add new">
          Editar categorías
        </option>
      </select>

      {addCategories && (
        <div className={`categories-overlay ${hideForm ? 'd-none' : ''}`}>
          <div className="categories-form">
            <h2>Editar Categorías</h2>
            <p>Puedes tener un máximo de 3 categorías</p>

            <label className="control-label">
              Categoría #1
              <input
                type="text"
                name="categories[create][]"
                value={newCategories[0] ?? ''}
                onChange={(e) => handleCategoryChange(e, 0)}
                placeholder="Agregar categoría"
                className="form-control"
              />
            </label>

            <label className="control-label">
              Categoría #2
              <input
                type="text"
                name="categories[create][]"
                value={newCategories[1] ?? ''}
                onChange={(e) => handleCategoryChange(e, 1)}
                placeholder="Agregar categoría"
                className="form-control"
              />
            </label>

            <label className="control-label">
              Categoría #3
              <input
                type="text"
                name="categories[create][]"
                value={newCategories[2] ?? ''}
                onChange={(e) => handleCategoryChange(e, 2)}
                placeholder="Agregar categoría"
                className="form-control"
              />
            </label>

            <div className="categories-form__buttons">
              <button type="button" onClick={handleRemoveQueue}>Cancelar</button>
              <button type="button" onClick={handleAddQueue}>Aceptar</button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

MenuServiceCategory.propTypes = {
  allCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentCategory: PropTypes.object
};

export default MenuServiceCategory;
