import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const SortableImage = ({ name, file, url, signedId }) => {
  const fileInput = useRef();

  useEffect(() => {
    if (fileInput.current) {
      fileInput.current.files = file
    }
  }, [])
  
  return (
    <>
      {file && 
        <>
          <input type="file" name={name} ref={fileInput} className="d-none" />
          <img src={url} />
        </>
      }

      {!file && 
        <>
          <input type="hidden" name={name} value={signedId} />
          <img src={url} />
        </>
      }
    </>
  );
};

SortableImage.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  signedId: PropTypes.string,
  files: PropTypes.object,
};

export default SortableImage;
