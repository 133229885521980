import React from "react";

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.99974 5.58623L11.9497 0.63623L13.3637 2.05023L8.41374 7.00023L13.3637 11.9502L11.9497 13.3642L6.99974 8.41423L2.04974 13.3642L0.635742 11.9502L5.58574 7.00023L0.635742 2.05023L2.04974 0.63623L6.99974 5.58623Z" />
  </svg>
);

const ArticlePopup = ({
  setShowPopUp,
  articles,
  articleArea,
  addArticleCard,
}) => {
  const selectArticle = (article) => {
    addArticleCard(articleArea, article);
    setShowPopUp(false);
  };

  return (
    <div className="popup-container">
      <div className="popup">
        <div className="popup__header">
          <div className="popup__header__description">
            <h2 className="popup__header__description__title">Lista General</h2>
            <p className="popup__header__description__subtitle">
              Elige el artículo a mostrar en la posición {articleArea}
            </p>
          </div>
          <div className="popup__header__close">
            <button
              className="popup__header__close__button"
              onClick={() => setShowPopUp(false)}
            >
              {closeIcon}
            </button>
          </div>
        </div>
        <div className="popup__body d-flex flex-column overflow-auto">
          {articles.length > 0 &&
            articles.map((article) => (
              <button
                className="article-select-btn"
                onClick={() => selectArticle(article)}
              >
                {article.title}
              </button>
            ))}
          {articles.length === 0 && (
            <div className="empty-table">
              <p className="empty-table__description">
                No existen articulos que mostrar
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ArticlePopup.propTypes = {
  articles: [],
};

export default ArticlePopup;
