import React from 'react'
import PropTypes from 'prop-types'

const ArticleType = ({ setArticleType, articleTypes }) => {

  const handleArticleType = ( article ) => {
    setArticleType(article);
  }

  return (
    <>
      <h1 className="article-types__title text-center">Elija el tipo de articulo a crear</h1>
      <div className="article-types">
        {
          articleTypes.map((article, index) => (
            <div
              className="article-types__item"
              key={ index }
              onClick={() => handleArticleType(article) }
            >
              <h2 className="article-types__item__title text-center">{ article.name }</h2>
              <img src={article.iconSvg} />
              <p className="article-types__item__description">{ article.description }</p>
            </div>
          ))
        }
      </div>
    </>
  )
}

ArticleType.propTypes = {}

export default ArticleType