import React, { useState, useEffect } from 'react';

const UserSuggest = ({ input, currentUser }) => {
    const [email, setEmail] = useState('')
    const [suggestions, setSuggestions] = useState([]);
    const [userSelected, setUserSelected] = useState(currentUser);

    const getUsersByEmail = async (email) => {
        try {
            const response = await fetch(`/filters/users/get_users?name=${email}`);
            const users = await response.json();
            //setCommerceNames(users);
            //checkCommerceName();
            setSuggestions(users)
        } catch (error) {
            throw new Error(`Something failed on get users`);
        }
    }

    const handleSelectUser = (user) => {
        setUserSelected(user);
        setSuggestions([]);
        setEmail('');
    }

    useEffect(() => {
        if (email !== '' ) {
            getUsersByEmail(email);
        } else {
            setSuggestions([]);
        }
    }, [email])

    return (
        <div class="suggestion-form">
            <input
                type="text"
                className="form-control"
                value={ email }
                onChange={ ({target}) => { setEmail(target.value) } }
                placeholder={ input.placeholder || 'Busca un usuario' }
            />
            { 
                userSelected &&
                    <>
                        <input
                            type="hidden"
                            value={ userSelected.id }
                            name={`${input.model}[${input.name}]`}
                        />
                        <p className="suggestion-form__info text-muted mt-1"><strong>Usuario encargado:</strong>  {userSelected.email}</p>
                    </>
            }
            <ul className="suggestion-records-list">
                {
                    suggestions.map(suggestion => (
                        <li
                            className=""
                            key={ suggestion.id }
                            onClick={ () => {handleSelectUser(suggestion)} }
                        >
                            { suggestion.email } ({ suggestion.name } { suggestion.last_name })
                        </li>
                    ))
                }
                
            </ul>
        </div>
    );
}

export default UserSuggest;
