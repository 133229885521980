import React from "react";
import { useArticleFilters } from "../../hooks/useArticleFilter";

const OttVideoFilter = ({ authors }) => {
  const { urlSearch, selectOption } = useArticleFilters();

  const handleSelectOption = ({ target }) => {
    selectOption(target);
  };

  return (
    <div className="d-flex">
      <select
        className="form-select"
        onChange={handleSelectOption}
        value={urlSearch.author}
        name="author"
      >
        <option value="">Elegir autor</option>
        {authors.map(({ id, name }, index) => (
          <option value={id} key={index}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

OttVideoFilter.propTypes = {};

export default OttVideoFilter;
